import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'boutiques',
  initialState: {
    boutiques: [],
    currentBoutique: undefined
  },

  reducers: {
    fetchBoutiquesRequest(state, action) {
      state.loading = true;
      state.error = undefined;
    },
    fetchBoutiquesSuccess(state, action) {
      state.loading = false;
      state.boutiques = action.payload;
    },
    fetchBoutiquesFailed(state, action) {
      state.loading = false;
      state.boutiques = [];
      state.error = action.payload;
    },
    setCurrentBoutique(state, action){
      state.currentBoutique = action.payload;
    },
    clearBoutiquesError(state) {
      state.error = undefined;
    },
  },
  extraReducers: {
    'clearErrors': (state) => {
      state.error = undefined;
      state.loading = false;
    }
  }
});

export { reducer, actions };