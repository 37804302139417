import React from 'react';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';


export const StockRestocksNewExistingRestock = ({ existing_restock_id }) => (
  <div className='text-center'>
    <div className='mb-2'>
      <Image src='http://cdn.kelbongoo.com/images/banane-sad.svg' style={{ width:'150px'}} />
    </div>
    <div className='italic'>
      <Link to={`/stock/restocks/${existing_restock_id}`}>
        Il y a d&eacute;j&agrave; un r&eacute;appro ouvert !
      </Link>
    </div>
  </div>
)