import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const createStockAdjustment = ({
  boutique,
  epp,
  createAlert
}) => (update) => {

  const caller = wrapClientApiCall({
    url: 'boutiques/update-tracked-item-quantity'
  })

  return caller({
    params: {
      producerproduct_id: epp.producerproduct_id,
      transaction_id: new Date().getTime().toString(),
      boutique,
      ...update
    }
  })
    .catch(err => createAlert({ message: err.message }))
}