import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

const fields = { producer_name: 1, product_name: 1, unit_display: 1, producerproduct_id: 1, consumer_price: 1, tva: 1 };


export const runAddItemSearch = ({
  currentBoutique,
  createAlert
}) => ({ search }) => {

  const caller = wrapClientApiCall({
    url: 'boutiqueproducerproducts/fetch'
  });

  return caller({
    params: {
      boutique: currentBoutique,
      product_name: search,
      // producerproduct_id: { $nin: usedProducerProductIds }
    },
    options: {
      fields,
      sort: { product_name: 1 },
      limit: 8
    }
  })
    .then(({ items }) => items)
    .catch(err => createAlert({ message: err.message }));
};