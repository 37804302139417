import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';
import { run as isObjectEmpty } from '@kelbongoo/shared-methods/utils/isObjectEmpty';


export const updateEquipmentStatus = ({
  equipment,
  setEquipment,
  createAlert
}) => update => {

  if(isObjectEmpty(update)){
    return;
  }

  const caller = wrapClientApiCall({
    url: 'equipments/update-status'
  });
  
  return caller({
    params: {
      equipment_id: equipment._id,
      update
    }
  })
    .then(({ updatedDoc }) => {
      setEquipment(updatedDoc)
    })
    .catch(err => createAlert({ message: err.message }));
};

