import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';

export const breadcrumbs = (inventory) => inventory && [{
  type: 'breadcrumb',
  href: '/stock',
  title: 'Stock'
},{
  type: 'breadcrumb',
  href: '/stock/inventories',
  title: 'Inventaires'
},{
  title: `${inventory.name} (${formatDate(inventory.date, 'ddd DD MMM YYYY')})`,
  type: 'breadcrumb'
}];