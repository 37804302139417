
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { ColdChainHome } from '@kelbongoo/shared-client-react-v2/dist/components/ColdChainHome';
import { breadcrumbs } from './helpers';


export const ColdChain = () => {

  const breadcrumbItems = breadcrumbs();
  const { currentBoutique, boutiques } = useSelector(state => state.boutiques);
  const boutique = boutiques.find(b => b.code === currentBoutique);

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <ColdChainHome
          context_name={boutique.name}
          recorders={[]}
          createManualReading={() => {}}
          refreshData={() => Promise.resolve({})}
          context_key='boutique'
          context_value={boutique.code}
        />
      </Container>
    </>
  )
}