import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';

export const breadcrumbs = (boutiqueday, boutiqueorder) => [
  {
    type: 'breadcrumb',
    title: 'Ventes',
    href: '/boutiquedays'
  },
  {
    type: 'breadcrumb',
    title: formatDate(boutiqueday.openedAt, 'ddd DD MMM YYYY'),
    href: `/boutiquedays/${boutiqueday._id}`,
  },
  {
    type: 'breadcrumb',
    title: 'Commandes',
    href: `/boutiquedays/${boutiqueday._id}/orders`,
  },
  {
    type: 'breadcrumb',
    title: formatDate(boutiqueorder.createdAt, 'HH:mm'),
  },
  {
    title: 'Créer une commande',
    icon: 'plus',
    href: `/boutiquedays/${boutiqueday._id}/orders/new`,
    type: 'menu',
    condition: boutiqueday.openedAt,
  },
];