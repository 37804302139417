import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash.clonedeep";

export const addComment = ({
  boutiquerestock,
  setRestock,
  createAlert
}) => comment => {

  const caller = wrapClientApiCall({
    url: 'boutiquerestocks/add-comment'
  })

  return caller({
    boutiquerestock_id: boutiquerestock._id,
    comment
  })
    .then(({ comments }) => {

      const updatedRestock = cloneDeep(boutiquerestock);
      updatedRestock.comments = comments;
      setRestock(updatedRestock)
    })
    .catch(err => createAlert({ message: err.message }));
};