import { library } from '@fortawesome/fontawesome-svg-core'

import { 
  faArrowLeft,
  // faArrowRight,
  // faArrowUp,
  faArrowDown,
  faBan,
  faBarcode,
  faBox,
  faBoxOpen,
  // faBullhorn,
  faCalendar,
  faCarrot,
  faCheck,
  // faCheckCircle,
  faClock,
  faCog,
  faComment,
  faDollyFlatbed,
  faEuroSign,
  // faExchangeAlt,
  faExclamationCircle,
  // faHome,
  faInfoCircle,
  // faList,
  // faMinus,
  faPaperPlane,
  faPlus,
  faPrint,
  faQuestionCircle,
  // faSignInAlt,
  faRandom,
  faSignOutAlt,
  faSitemap,
  // faSnowflake,
  faStar,
  faSync,
  faTag,
  faTasks,
  faTimes,
  faTruck,
  faUser,
  faUserTag,
  faThumbsUp,
  faWineBottle,
  faCaretRight,
  faReceipt,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

import { 
  faClock as farClock
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faArrowLeft,
  // faArrowRight,
  // faArrowUp,
  faArrowDown,
  faBan,
  faBarcode,
  faBox,
  faBoxOpen,
  // faBullhorn,
  faCalendar,
  faCarrot,
  faCheck, 
  // faCheckCircle,
  faClock,
  faCog,
  faComment,
  faDollyFlatbed,
  faEuroSign,
  // faExchangeAlt,
  faExclamationCircle,
  // faHome,
  faInfoCircle,
  // faList,
  // faMinus,
  faPaperPlane,
  faPlus,
  faPrint,
  faQuestionCircle,
  faRandom,
  // faSignInAlt, 
  faSignOutAlt, 
  faSitemap,
  // faSnowflake,
  faStar,
  faSync,
  faTag,
  faTasks,
  faThumbsUp,
  faTimes,
  faTruck,
  faUser, 
  faUserTag,
  faWineBottle,
  farClock,
  faCaretRight,
  faReceipt,
  faExclamationTriangle
);