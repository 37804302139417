import { createAlert } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/actions";
import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const fetchData = ({
  currentBoutique,
  setLoading,
  setExistingBoutiqueDay,
  setTransfers,
  setPreviousCaisse,
  createAlert
}) => {

  const caller = wrapClientApiCall({
    url: 'boutiques/get-create-boutiqueday-data'
  })

  setLoading(true);

  return caller({ 
    params: {
      boutique: currentBoutique
    }
  })
    .then(data => {

      if(!data){
        return;
      }
      if(data.hasActive){
        return setExistingBoutiqueDay(true);
      }

      setPreviousCaisse(data.previous_caisse || 0);

      setTransfers(data.transfers);
    })
    .catch(err => createAlert({ message: err.message }))
    .finally(() => setLoading(false));
};