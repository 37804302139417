import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const cancelPayment = ({
  currentBoutique,
  boutiqueday_id,
  createAlert,
  loadBoutiqueOrder
}) => ({ boutiqueorder_id }) => {

  const caller = wrapClientApiCall({
    url: 'boutiques/cancel-locale-payment'
  })

  return caller({
    params: {
      boutiqueorder_id,
      boutique: currentBoutique,
      boutiqueday_id,
      userId: '2W8X2SaMhytesy4ne', // WARN
    },
  })
    .then(() => {
      createAlert({ type: 'success' });
      loadBoutiqueOrder();
    })
    .catch((err) => createAlert({ message: err.message }));
};