import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const createBoutiqueDay = ({
  startData,
  transfersData,
  createAlert,
  setRedirectToCaisse
}) => () => {

  const caller = wrapClientApiCall({
    url: 'boutiques/create-boutiqueday'
  })

  return caller({
    params: {
      boutique: this.currentBoutique(), 
      data: startData, 
      transfersData
    }
  })
    .then(() => setRedirectToCaisse(true))
    .catch(err => createAlert({ message: err.message }));
};