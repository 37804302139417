import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addPayment = ({
  currentBoutique,
  boutiqueday,
  order,
  createAlert
}) => ({ payments, added, added_reduced }) => {

  const caller = wrapClientApiCall({
    url: 'globalorderlocales/add-locale-payment'
  })

  return caller({
    params: {
      locale: currentBoutique,
      boutiqueday_id: boutiqueday._id,
      order_id: order._id,
      payment: payments.slice(-1)[0],
      added,
      added_reduced
    }
  })
    .then()
    .catch(err => createAlert({ message: err.message }));
};