import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'session',
  initialState: {},
  reducers: {},
  extraReducers: {
    'clearErrors': (state) => {
      state.error = undefined;
      state.loading = false;
    }
  }
});

export { reducer, actions };