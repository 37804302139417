import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { Order } from '../../../../models/Order';

export const updateItem = ({
  order,
  setOrder,
  createAlert
}) => (producerproduct_id, listName) => params => {

  params.list = listName;
  params.producerproduct_id = producerproduct_id;
  params.isConsumer = false;

  if (params.action === 'remove') {
    params.reason = 2; // removed during distribution
  }

  if (
    !Order.validateUpdateItemParams({ order, params, isConsumer: false })
  ) {
    return createAlert({ message: 'Invalid update parameters' });
  }

  const caller = wrapClientApiCall({
    url: 'orders/update-item'
  })

  return caller({
    params: {
      order_id: order._id,
      params
    }
  })
    .then(({ updatedDoc }) => {
      setOrder(updatedDoc);
    })
    .catch(err => createAlert({ message: err.message }));
};