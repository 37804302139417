import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import areDatesSameDay from '@kelbongoo/shared-methods/utils/areDatesSameDay';

export const createTaskInstanceGroupEvent = (tasks, { urlPrefix = '/tasks/checklist', url } = {}) => {

  if(tasks.length === 0){
    return [];
  }
  const tasksDone = tasks.filter(
    task => !!task.doneAt
  );

  return {
    event_type: 'tasks',
    title: 'Tâches',
    eventInfo: `${tasksDone.length} / ${tasks.length}`,
    path: url
      ? url
      : `${urlPrefix}/${formatDate.run(new Date(tasks[0].date), 'YYYY-MM-DD')}`,
    progressPct: tasks.length > 0
      ? Math.round(
          ( tasksDone.length / tasks.length ) * 100
        )
      : 0,
    isDateToday: date => areDatesSameDay(date, new Date(tasks[0].date)),
    hasProgress: true
  };
}


export const groupTasksByDay = tasks => {
	const output = {};
	for(let task of tasks){
		let date = formatDate(new Date(task.date), 'YYYY-MM-DD');
		if(!output[date]){
			output[date] = []
		}
		output[date].push(task);
	}
	return output;
}

export const formatTasks = (tasks, options = {}) => {

  tasks = tasks.filter(
    t => !t.cancelledAt
  )

  if(tasks.length === 0){
    return [];
  }

  const groups = groupTasksByDay(tasks);
  const output = Object.keys(groups).map(
    date => createTaskInstanceGroupEvent(groups[date], Object.assign({}, options, {
      url: '/tasks/'+date
    }))
  );
  return output;
}