import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';

import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { BoutiqueOrdersDetail } from '@kelbongoo/shared-client-react-v2/dist/components/BoutiqueOrdersDetail';

import { genericDetailLoader } from '../../../utils';
import { breadcrumbs } from './helpers';
import { BoutiqueOrder } from '../../../models/BoutiqueOrder';
import {
  addComment as addCommentBase,
  addPayment as addPaymentBase,
  cancelPayment as cancelPaymentBase,
  fetchConsumerCredit as fetchConsumerCreditBase,
  printReceipt as printReceiptBase,
  runConsumerSearch as runConsumerSearchBase
} from './actions';


export const BoutiqueDaysDetailOrdersDetail = () => {

  const dispatch = useDispatch();

  const { boutiqueday_id, boutiqueorder_id } = useParams();

  const { currentBoutique, boutiques } = useSelector(state => state.boutiques);

  const [boutiqueday, setBoutiqueDay] = useState();
  const [boutiqueorder, setBoutiqueOrder] = useState();
  const boutique = boutiques.find(b => b.code === currentBoutique)

  const createAlert = d => dispatch(createAlertBase(d))

  const loadBoutiqueOrder = () => genericDetailLoader({
    setItem: setBoutiqueOrder,
    params: {
      boutiqueday_id,
      boutique: currentBoutique,
      boutiqueorder_id
    },
    url: 'boutiqueorders/fetch-detail',
    key: 'boutiqueorder',
    createAlert
  })

  const loadBoutiqueDay = () => genericDetailLoader({
    setItem: setBoutiqueDay,
    params: {
      boutiqueday_id,
      boutique,
    },
    url: 'boutiquedays/fetch-detail',
    key: 'boutiqueday',
    createAlert
  });

  useEffect(() => {
    loadBoutiqueDay();
    loadBoutiqueOrder();
  }, [])

  if (!boutiqueorder || !boutiqueday) {
    return <LoadingSpinner />
  }

  const breadcrumbItems = breadcrumbs(boutiqueday, boutiqueorder);

  const addPayment = addPaymentBase({
    boutiqueorder,
    boutiqueday_id,
    currentBoutique,
    createAlert
  });

  const addComment = addCommentBase({
    boutiqueorder,
    setBoutiqueOrder,
    createAlert
  });

  const cancelPayment = cancelPaymentBase({
    currentBoutique,
    boutiqueday_id,
    createAlert,
    loadBoutiqueOrder
  });

  const fetchConsumerCredit = fetchConsumerCreditBase({ createAlert });

  const runConsumerSearch = runConsumerSearchBase({ createAlert });

  const printReceipt = printReceiptBase({
    boutiqueorder,
    locale: boutique // TODO not enough parameters
  });

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
     
      <Container fluid>

        <BoutiqueOrdersDetail
          boutiqueorder={new BoutiqueOrder(boutiqueorder)}
          allowAddPayment={true}
          runConsumerSearch={runConsumerSearch}
          fetchConsumerCredit={fetchConsumerCredit}
          addPayment={addPayment}
          allowAddComment={true}
          addComment={addComment}
          getAmountPaid={BoutiqueOrder.getAmountPaid}
          printReceipt={printReceipt}
          showPrintReceipt={true}
          showCancelPayment={true}
          cancelPayment={cancelPayment}
        />
      </Container>
      
    </>
  )
}