import model from '@kelbongoo/shared-methods/inventories/model';
import collection from '@kelbongoo/shared-methods/inventories/collection';

class Inventory {

  constructor(data){

    Object.assign(this, data);
  }
}

Object.assign(
  Inventory.prototype,
  model
);

Object.assign(
  Inventory,
  collection
);

export {
  Inventory
}