import { updateTransferItems as updateTransferItemsBase } from "./updateTransferItems";
import { receiveTransfer as receiveTransferBase } from "./receiveTransfer";
import { createBoutiqueDay as createBoutiqueDayBase } from "../actions";


export const buildParams = ({
  previousCaisse,
  startData,
  transfers,
  transfersData,
  setTransfers,
  setTransfersData,
  setStartData,
  setRedirectToCaisse,
  createAlert
}) => {

  const updateTransferItems = updateTransferItemsBase({
    transfers,
    transfersData,
    setTransfers,
    setTransfersData
  })
  const receiveTransfer = receiveTransferBase({
    transfers,
    transfersData,
    setTransfers,
    setTransfersData
  })
  const createBoutiqueDay = createBoutiqueDayBase({
    startData,
    transfersData,
    createAlert,
    setRedirectToCaisse
  })

  const caisseday = {
    cash_start_application: previousCaisse,
    hasOpenData: () => !!startData,
    isCashStartInvalid: ({ change_start, bills_start }) => {
      return change_start !== 'undefined'
        && bills_start !== 'undefined'
        && previousCaisse !== (change_start + bills_start);
    },
    hasBeenOpened: () => false
  };

  return {
    openParams: {
      caisseday,
      saveOpenData: data => {
        setStartData(data);
        return Promise.resolve();
      } 
    },
    checkinsParams: {
      transfers: transfers.filter(
        transfer => transfer.transfer_type === 'checkin'
      ),
      caisseday,
      allowCheckinReception: true,
      updateTransferItems,
      receiveTransfer,
      openCaisse: createBoutiqueDay
    }
  }
}