import model from '@kelbongoo/shared-methods/boutiquerestocks/model';
import collection from '@kelbongoo/shared-methods/boutiquerestocks/collection';

class BoutiqueRestock {

  constructor(data){

    Object.assign(this, data);
  }

  allowAddComment(){
    return true;
  }
}

Object.assign(
  BoutiqueRestock.prototype,
  model
);

Object.assign(
  BoutiqueRestock,
  collection
);

export {
  BoutiqueRestock
}