
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { 
  Datatable,
  updateQuery as updateQueryBase,
  refreshData as refreshDataBase
} from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { 
  breadcrumbs,
  buildFilters
} from './helpers';
import { InventoriesHomeRow } from './InventoriesHomeRow';


export const InventoriesHome = () => {

  const breadcrumbItems = breadcrumbs();

  const dispatch = useDispatch();
  const { currentBoutique } = useSelector(state => state.boutiques);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState();
  const [page, setPage] = useState(1);
  const createAlert = d => dispatch(createAlertBase(d))
  const updateQuery = updateQueryBase({ setCurrentQuery, setPage, currentQuery })
  const refreshData = refreshDataBase({
    url: 'inventories/fetch',
    setItems,
    setCount,
    createAlert,
    setLoading,
    selectorAddons: {
      context_value: currentBoutique,
			context_key: 'boutique'
    }, 
    selectFields: {
      date: 1,
      tag: 1,
      doneAt: 1
    }, 
    sortFields: { date: -1 }
  })
  useEffect(() => {

    if(!currentQuery){
      return;
    }

    refreshData({
      currentQuery, 
      page,
      docsPerPage
    })
  }, [currentQuery, page, docsPerPage]);
  
  const { daterangeFilter, quickFilter } = buildFilters();

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Datatable
          columns={[
            { title: 'Date' },
            { title: 'Tag' },
            { title: 'Sortie', width: '150px', classes: 'text-center' }
          ]}
          DatatableRow={InventoriesHomeRow} 
          items={items} 
          count={count} 
          loading={loading} 
          updateQuery={updateQuery} 
          setDocsPerPage={setDocsPerPage} 
          quickFilter={quickFilter} 
          daterangeFilter={daterangeFilter} 
          useSelector={useSelector}
          dispatch={dispatch}
          showPagination={true}
          currentPage={page}
          updatePage={setPage}
        />
      </Container>
    </>
  )
}