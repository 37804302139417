import React from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

export const StockRestocksNewSelectProducer = ({
  producers,
  setSelectedProducer
}) => {

  return (
    <div className='mb-2'>
      <Card body className='clearfix'>
        <Form.Control as='select' size='lg' onChange={setSelectedProducer} >
          <option disabled value='placeholder'>Choisir un producteur</option>
          {producers.map(p => (
            <option value={p._id}>{p.name}</option>
          ))}
        </Form.Control>
      </Card>
    </div>
  )
}