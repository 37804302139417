
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { DefaultForm } from '@kelbongoo/shared-client-react-v2/dist/components/DefaultForm';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { genericDetailLoader } from '../../../utils';
import { breadcrumbs } from './helpers';
import {
  update,
  updateLots
} from './actions';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';


export const StockDetail = () => {

  const dispatch = useDispatch();
  const { producerproduct_id } = useParams();
  const { currentBoutique: boutique } = useSelector(state => state.boutiques);
  const [loading, setLoading] = useState(true);
  const [epp, setEpp] = useState();
  const createAlert = d => dispatch(createAlertBase(d))
  const breadcrumbItems = breadcrumbs(epp);
  
  useEffect(() => {
    genericDetailLoader({
      setLoading, 
      setItem: setEpp,
      params: {
        producerproduct_id, 
        boutique,
      },
      url: 'boutiqueproducerproducts/fetch-detail',
      key: 'boutiqueproducerproduct',
      createAlert
    })
  }, [])

  if(loading){
    return <LoadingSpinner />
  }

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Row className='mb-4 mt-4'>
          <Col sm={6} className='text-center'>
            <div className='text-xl bold'>
              {epp.product_name}
            </div>
            <div className='text-md'>
              {epp.producer_name}
            </div>
            <div className='font-md'>
              {epp.unit_display}
            </div>
          </Col>
          <Col sm={6}>
            
          </Col>
        </Row>
        <div>
          <DefaultForm
            item={epp}
            groups={[]}
            elements={[]}
          />
        </div>
      </Container>
    </>
  )
}