import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash.clonedeep";

export const toggleItemCompleted = ({
  boutique,
  inventory,
  setInventory,
  createAlert
}) => (reference_id) => (isCompleted) => {

  const caller = wrapClientApiCall({
    url: 'inventories/update-item'
  })

  return caller({
    params: {
      inventory_id: inventory._id,
      reference_id,
      completed: isCompleted
    }
  })
    .then(({ updatedDoc }) => {

      const updatedInventory = cloneDeep(inventory);
      const index = updatedInventory.items.findIndex(
        i => i.reference_id === reference_id
      );
      updatedInventory.items[index].completed = updatedDoc.items[index].completed;
      setInventory(updatedInventory);
    })
    .catch(err => createAlert({ message: err.message }));
};