import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash.clonedeep";

export const toggleItemCompleted = ({
  boutiquerestock,
  createAlert,
  setRestock
}) => (producerproduct_id) => completed => {

  const caller = wrapClientApiCall({
    url: 'boutiqueresotcks/update-item'
  })

  return caller({
    params: {
      boutiquerestock_id: boutiquerestock._id,
      producerproduct_id,
      completed
    }
  })
    .then(({ updatedDoc }) => {

      const updatedRestock = cloneDeep(boutiquerestock);
      const ind = boutiquerestock.items.findIndex(
        i => i.producerproduct_id === producerproduct_id
      );
      let updatedInd = updatedDoc.items.findIndex(
        i => i.producerproduct_id === producerproduct_id
      );
      updatedRestock.items[ind].reception_completed = updatedDoc.items[updatedInd].reception_completed;

      return setRestock(updatedRestock);
    })
    .catch(err => createAlert({ message: err.message }));
};