import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash/cloneDeep";

export const removeTransfer = ({
  transfers,
  setTransfers,
  createAlert
}) => transfer_id => {

  const caller = wrapClientApiCall({
    url: 'caissedays/remove-transfer'
  });

  return caller({ 
    params: { transfer_id }
  })
    .then(() => {

      const updatedTransfers = cloneDeep(transfers);

      let ind = updatedTransfers.findIndex(
        t => t._id === transfer_id
      );
      updatedTransfers.splice(ind, 1);

      return setTransfers(updatedTransfers);
    })
    .catch(err => createAlert({ message: err.message }));
};