import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall"

export const markPickedUp = ({
  refreshData,
  createAlert
}) => order_id => () => {

  const caller = wrapClientApiCall({
    url: 'orders/mark-picked-up'
  });

  return caller({
    params: {
      order_id
    }
  })
    .then(() => refreshData({ page: 0, currentQuery: {} }))
    .catch(err => createAlert({ message: err.message }))
}