import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from 'lodash.clonedeep';

export const updateItemQuantity = ({
  boutique,
  inventory,
  setInventory,
  createAlert
}) => (reference_id, quantity) => {

  const caller = wrapClientApiCall({
    url: 'inventories/update-item'
  });

  return caller({
    params: {
      inventory_id: inventory._id,
      reference_id,
      quantity_received: quantity
    }
  })
    .then(({ updatedDoc }) => {

      const updatedInventory = cloneDeep(inventory);
      const index = updatedInventory.items.findIndex(
        i => i.reference_id === reference_id
      );
      updatedInventory.items[index].quantity_expected = updatedDoc.items[index].quantity_expected;
      updatedInventory.items[index].quantity_received = updatedDoc.items[index].quantity_received;
      setInventory(updatedInventory);
    })
    .catch(err => createAlert({ message: err.message }));
};