import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LoginBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { fetchBoutiques } from '../../../actions';
import { actions as sessionActions } from '../../../reducers/session';
import { actions as boutiquesActions } from '../../../reducers/boutiques';
import { ErrorBoundary } from '../../ui/ErrorBoundary';

export const LoginSubBase = props => {

	const dispatch = useDispatch();
	const { boutiques } = useSelector(state => state.boutiques)

	useEffect(() => {
		if(!boutiques.length){
			dispatch(fetchBoutiques());
		}
	}, [dispatch, boutiques])

	const extraParams = [{
		name: 'boutique',
		label: 'Boutique',
		placeholder: 'Choisir une boutique',
		required: true,
		options: boutiques && boutiques.map(boutique => ({
			name: boutique.name,
			value: boutique.code
		}))
	}]
	const setExtraParams = ({ boutique }) => {
		dispatch(boutiquesActions.setCurrentBoutique(boutique))
	};
	const onLoginSuccess = () => dispatch(sessionActions.setInit(true));

  return (
		<LoginBase 
			extraParams={extraParams} 
			setExtraParams={setExtraParams} 
			onLoginSuccess={onLoginSuccess}
			Link={Link}
			Redirect={Redirect}
			dispatch={dispatch}
			useSelector={useSelector}
			{...props} 
		/>
	)
}

export const Login = props => (
	<ErrorBoundary>
		<LoginSubBase {...props} />
	</ErrorBoundary>
)