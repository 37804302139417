import React from 'react';
import { Link } from 'react-router-dom';

import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { MoneyDisplay } from '@kelbongoo/shared-client-react-v2/dist/components/MoneyDisplay';


export const BoutiqueDaysHomeRow = ({ item }) => {
  return (
    <tr className={item.isOpen() ? 'table-success' : ''}>
      <td>
        <Link to={`/boutiquedays/${item._id}`}>
          {formatDate(item.openedAt, 'ddd DD MMM YYYY')}
        </Link>
      </td>
      {item.isOpen() ? (
        <td colspan='3' className='text-center italic'>
          En cours
        </td>
      ) : (
        <>
          <td className='text-center'>{item.orders_total}</td>
          <td className='text-center'>{item.units_total}</td>
          <td className='text-center'>
            <MoneyDisplay value={item.amount_total} />
          </td>
        </>
      )}
      <td className='text-center bold'>
        {item.closedAt ? 'Clôturée' : 'Ouverte'}
      </td>
    </tr>
  )
}