export const buildFilters = (producers = []) => {

  const selectFilters = [{
    keys: ['producer_id'],
    options: producers.map(p => ({
      name: p.name,
      value: p._id
    })),
    valueMapping(value) {
      if (value === 'all') return {};
      return { producer_id: value };
    }
  }]
  const quickFilter = {
    keys: ['status'],
    items: [
      { name: 'Tous', value: 'all' },
      { name: 'Créé', value: 0 },
      { name: 'En cours', value: 'running' },
      { name: 'Reçu', value: 4 }
    ],
    valueMapping(value) {
      if (value === 'all') return {};
      if (value === 'running') return { status: { $gt: 0, $lt: 4 } };
      return { status: value };
    }
  }
  const daterangeFilter = {
    keys: ['delivery_date']
  }

  return {
    selectFilters,
    quickFilter,
    daterangeFilter
  }
}