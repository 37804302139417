
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { InventoriesDetailView } from '@kelbongoo/shared-client-react-v2/dist/components/InventoriesDetailView';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { breadcrumbs } from './helpers';
import { Inventory } from '../../../models/Inventory';
import { genericDetailLoader } from '../../../utils';
import {
  validateInventory as validateInventoryBase,
  updateItemQuantity as updateItemQuantityBase,
  toggleItemCompleted as toggleItemCompletedBase,
  addComment as addCommentBase
} from './actions';


export const InventoriesDetail = () => {

  const dispatch = useDispatch();
  const { inventory_id } = useParams();
  const { currentBoutique: boutique } = useSelector(state => state.boutiques);
  const [loading, setLoading] = useState(true);
  const [inventory, setInventory] = useState();
  const createAlert = d => dispatch(createAlertBase(d))
  const breadcrumbItems = breadcrumbs(inventory);

  const params = {
    boutique,
    inventory,
    setInventory,
    createAlert
  }
  const validateInventory = validateInventoryBase(params);
  const updateItemQuantity = updateItemQuantityBase(params);
  const toggleItemCompleted = toggleItemCompletedBase(params);
  const addComment = addCommentBase(params);

  const updateLots = () => {}
  
  useEffect(() => {
    genericDetailLoader({
      setLoading, 
      setItem: setInventory,
      params: {
        inventory_id, 
        boutique,
      },
      url: 'inventories/fetch-detail',
      key: 'inventory',
      createAlert
    })
  }, [])

  if(loading){
    return <LoadingSpinner />
  }

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <div className='mt-2 mb-5'>
          <InventoriesDetailView
            inventory={new Inventory(inventory)}
            addComment={addComment}
            onValidation={validateInventory}
            itemSubsubtitleKey='unit_display'
            itemSubtitleKey='producer_name'
            itemTitleKey='product_name'
            referenceKey='producerproduct_id'
            showLots={true}
            toggleItemCompleted={toggleItemCompleted}
            updateItemQuantity={updateItemQuantity}
            updateLots={updateLots}
          />
        </div>
      </Container>
    </>
  )
}