import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash/cloneDeep";

export const createTransfer = ({
  transfers,
  setTransfers,
  createAlert
}) => caisseday => input => {

  const data = Object.assign({ transfer_type: 'checkout' }, input);
  const caller = wrapClientApiCall({
    url: 'caissedays/create-transfer'
  });

  return caller({ 
    params: { 
      caisseday_id: caisseday._id, 
      data 
    }
  })
    .then(newTransfer => {

      const updatedTransfers = cloneDeep(transfers);

      updatedTransfers.push(newTransfer);
      return setTransfers(updatedTransfers);
    })
    .catch(err => createAlert({ message: err.message }));
};