import getPaymentMethodTitle from '@kelbongoo/shared-methods/paymentMethods/getPaymentMethodTitle';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { run as formatMoney } from '@kelbongoo/shared-methods/utils/formatMoney';

const plugin = {
  PosPrinter: {
    printFormattedText: () => {}
  }
}

export const printReceipt = ({
  boutiqueorder,
  locale
}) => ({ items, reduced }) => {

    const payment =
      boutiqueorder &&
      boutiqueorder.payments &&
      boutiqueorder.payments.length > 0 &&
      boutiqueorder.payments[0];

    if (!payment) {
      return;
    }

    const payment_type = getPaymentMethodTitle(payment.method);
    const allItems = [...(items || []), ...(reduced || [])];

    plugin.PosPrinter.printFormattedText(
      [
        {
          boutique: {
            name: locale.name,
            address_street: locale.address.street,
            address_zip: locale.address.zip,
            address_city: locale.address.city,
          },
          date: formatDate(new Date(), 'dddd DD MMMM HH:mm'),
          order_id: boutiqueorder._id,
          payment_type,
          items: allItems.map((item) => {
            let product_name =
              item.product_name.length > 25
                ? `${item.product_name.substring(0, 5)}...`
                : item.product_name;

            return {
              product_name,
              quantity: item.quantity,
              consumer_price: formatMoney(item.reduced_unit_price),
              total_price: formatMoney(item.reduced_value),
            };
          }),
          amount_total: formatMoney(boutiqueorder.getAmountTotal()),
          amount_total_ht: formatMoney(boutiqueorder.getAmountTotalHT()),
          amount_tva: formatMoney(boutiqueorder.getAmountTotalTVA()),
        },
      ],
      (result) => console.log(result),
      (error) => console.log('plugin error ' + JSON.stringify(error))
    );
};