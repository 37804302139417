import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash.clonedeep";

export const updateItemQuantity = ({
  boutique,
  boutiquerestock,
  createAlert,
  setRestock
}) => (producerproduct_id) => quantity => {

  const caller = wrapClientApiCall({
    url: 'boutiquerestocks/update-item'
  })

  return caller({
    params: {
      boutique,
      boutiquerestock_id: boutiquerestock._id,
      producerproduct_id,
      quantity
    }
  })
    .then(({ updatedDoc }) => {
      
      const updatedRestock = cloneDeep(boutiquerestock);
      const ind = updatedRestock.items.findIndex(
        i => i.producerproduct_id === producerproduct_id
      );

      let updatedInd = updatedDoc.items.findIndex(
        i => i.producerproduct_id === producerproduct_id
      );

      updatedRestock.items[ind].quantity_received = updatedDoc.items[updatedInd].quantity_received;
      return setRestock(updatedRestock);
    })
    .catch(err => createAlert({ message: err.message }));
};