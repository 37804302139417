import { actions as boutiquesActions } from '../reducers/boutiques';
import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

const {
  fetchBoutiquesRequest,
  fetchBoutiquesFailed,
  fetchBoutiquesSuccess
} = boutiquesActions;



export const fetchBoutiques = () => (dispatch, getState) => {

  const { boutiques } = getState().boutiques;

  if(boutiques.length > 0){
    return;
  }

  dispatch(fetchBoutiquesRequest());

  const caller = wrapClientApiCall({ 
    url: 'boutiques/fetch',
    freeAccess: true
  })

  return caller()
    .then(({ items: boutiques }) => dispatch(fetchBoutiquesSuccess(boutiques)))
    .catch(error => dispatch(fetchBoutiquesFailed(error.message || error.name)));
};