import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash/cloneDeep";

export const addComment = ({
  boutiqueorder,
  setBoutiqueOrder,
  createAlert
}) => (comment) => {

  const caller = wrapClientApiCall({
    url: 'boutiqueorders/add-comment'
  });

  return caller({
    params: {
      reference_id: boutiqueorder._id, 
      comment,
      context:'boutique',
      userId: '2W8X2SaMhytesy4ne', // WARN
    }
  })
    .then(({ comments }) => {
      const updatedBoutiqueOrder = cloneDeep(boutiqueorder);
      updatedBoutiqueOrder.comments = comments;
      setBoutiqueOrder(updatedBoutiqueOrder);
    })
    .catch((err) => createAlert({ message: err.message }));
};