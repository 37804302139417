export const breadcrumbs = boutiqueproducerproduct => boutiqueproducerproduct && [{
  title: 'Stock',
  type: 'breadcrumb',
  href: '/stock'
},{
  title: boutiqueproducerproduct.product_name,
  type: 'breadcrumb'
},{
  title: 'Historique',
  type: 'menu',
  href: `/stock/${boutiqueproducerproduct.producerproduct_id}/events`,
  icon: 'list'
}];