import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';

export const breadcrumbs = (boutiquerestock) => boutiquerestock && [
  { type: 'breadcrumb', title: 'Stock', href: '/stock' },
  { type: 'breadcrumb', title: 'Réappros', href: '/stock/restocks' },
  {
    type: 'breadcrumb',
    title: boutiquerestock.producer_name + '-' +
      boutiquerestock.delivery_date
      ? formatDate(boutiquerestock.delivery_date, 'ddd DD MMM YYYY')
      : 'En attente'
  }
];