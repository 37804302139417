import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const genericDetailLoader = ({ 
  setLoading = () => {}, 
  setItem, 
  createAlert,
  url,
  params,
  key,
  wrapper
}) => {

  const caller = wrapClientApiCall({ url })

  setLoading(true);
  
  return caller({ params })
    .then(data => {

      let output;
      
      if(Array.isArray(data[key]) && data[key].length > 0){
        output = data[key][0];
      }
      else {
        output = data[key];
      }

      if(wrapper){
        output = wrapper(output);
      }
      setItem(output)
    })
    .catch(err => createAlert({ message: err.message }))
    .finally(() => {
      setLoading(false)
    });
};