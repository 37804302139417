
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container'

import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import {
  Datatable,
  updateQuery as updateQueryBase,
  refreshData as refreshDataBase
} from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';

import {
  breadcrumbs,
  buildFilters,
  buildColumns
} from './helpers';
import { BoutiqueDaysHomeRow } from './BoutiqueDaysHomeRow';
import { BoutiqueDay } from '../../../models/BoutiqueDay';

const selectFields = {
  openedAt: 1,
  closedAt: 1,
  amount_total: 1,
  units_total: 1,
  orders_total: 1
}


export const BoutiqueDaysHome = () => {

  const dispatch = useDispatch();
  const breadcrumbItems = breadcrumbs();

  const { currentBoutique } = useSelector(state => state.boutiques);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState();
  const [page, setPage] = useState(1);

  const createAlert = data => dispatch(createAlertBase(data))
  const updateQuery = updateQueryBase({ setCurrentQuery, setPage, currentQuery })
  const refreshData = refreshDataBase({
    url: 'boutiquedays/fetch',
    setItems,
    setCount,
    createAlert,
    setLoading,
    selectorAddons: { boutique: currentBoutique },
    selectFields,
    sortFields: { date: -1 }
  })

  const columns = buildColumns();
  const { selectFilters, quickFilter, daterangeFilter } = buildFilters();

  const loadData = () => {
    if (!currentQuery) return;
    refreshData({
      currentQuery,
      page,
      docsPerPage
    })
  }

  useEffect(loadData, [currentQuery, page, docsPerPage]);

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Datatable
          DatatableRow={BoutiqueDaysHomeRow}
          items={items.map(i => new BoutiqueDay(i))}
          count={count}
          loading={loading}
          updateQuery={updateQuery}
          setDocsPerPage={setDocsPerPage}
          columns={columns}
          // selectFilters={selectFilters} //empty
          // quickFilter={quickFilter} // not working
          // daterangeFilter={daterangeFilter} // not working
          useSelector={useSelector}
          dispatch={dispatch}
          showPagination={true}
          currentPage={page}
          updatePage={setPage}
        />
      </Container>
    </>
  )
}