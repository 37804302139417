
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { 
  Datatable,
  updateQuery as updateQueryBase,
  refreshData as refreshDataBase
} from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { 
  breadcrumbs,
  buildFilters,
  buildColumns
} from './helpers';
import { StockRestocksHomeRow } from './StockRestocksHomeRow';
import { BoutiqueRestock } from '../../../models/BoutiqueRestock';

const selectFields = {
  delivery_date: 1, 
  producer_name: 1,
  producer_id: 1,
  status: 1,
  items: 1,
  sentAt: 1,
  receivedAt: 1,
  preparation_date: 1
}


export const StockRestocksHome = () => {

  const breadcrumbItems = breadcrumbs();
  const { currentBoutique } = useSelector(state => state.boutiques);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState();
  const [page, setPage] = useState(1);
  const createAlert = data => dispatch(createAlertBase(data))
  const updateQuery = updateQueryBase({ setCurrentQuery, setPage, currentQuery })
  const refreshData = refreshDataBase({
    url: 'boutiquerestocks/fetch',
    setItems,
    setCount,
    createAlert,
    setLoading,
    selectorAddons: { boutique: currentBoutique }, 
    selectFields, 
    sortFields: { createdAt: -1 } 
  })
  const columns = buildColumns();

  useEffect(() => {

    if(!currentQuery){
      return;
    }

    refreshData({
      currentQuery, 
      page,
      docsPerPage
    })
  }, [currentQuery, page, docsPerPage]);

  const { selectFilters, quickFilter, daterangeFilter } = buildFilters();

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Datatable
          DatatableRow={StockRestocksHomeRow} 
          items={items.map(item => new BoutiqueRestock(item))} 
          count={count} 
          loading={loading} 
          updateQuery={updateQuery} 
          setDocsPerPage={setDocsPerPage} 
          columns={columns} 
          selectFilters={selectFilters} 
          quickFilter={quickFilter} 
          daterangeFilter={daterangeFilter}
          useSelector={useSelector}
          dispatch={dispatch}
          showPagination={true}
          currentPage={page}
          updatePage={setPage}
        />
      </Container>
    </>
  )
}