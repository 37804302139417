import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';

export const breadcrumbs = (boutiqueday) => [
  {
    title: 'Ventes',
    type: 'breadcrumb',
    href: '/boutiquedays'
  }, 
  {
    type: 'breadcrumb',
    title: formatDate(boutiqueday.openedAt, 'ddd DD MMM YYYY')
  },
  {
    type: 'menu',
    title: 'Commandes',
    href: `/boutiquedays/${boutiqueday._id}/orders`
  },
  {
    type: 'menu',
    title: 'Caisse',
    href: `/boutiquedays/${boutiqueday._id}/caisse`
  }
];