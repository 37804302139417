import React, { useState, useEffect } from 'react';
import { Redirect, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ValidationButton } from '@kelbongoo/shared-client-react-v2/dist/components/ValidationButton';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { DetailUpperRight } from '@kelbongoo/shared-client-react-v2/dist/components/DetailUpperRight';
import { IconText } from '@kelbongoo/shared-client-react-v2/dist/components/IconText';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';

import { ItemDetailDisplayFrame } from '../../ui/ItemDetailDisplayFrame';
import { genericDetailLoader } from '../../../utils';
import { breadcrumbs, getDisplays } from './helpers';
import {
  addComment as addCommentBase,
  closeBoutiqueDay as closeBoutiqueDayBase
} from './actions';


export const BoutiqueDaysDetail = () => {

  const dispatch = useDispatch();

  const { boutiqueday_id } = useParams();

  const { currentBoutique: boutique } = useSelector(state => state.boutiques);
  const [loading, setLoading] = useState(true);
  const [redirectToCaisse, setRedirectToCaisse] = useState(false);
  const [boutiqueday, setBoutiqueDay] = useState();

  const createAlert = d => dispatch(createAlertBase(d))

  const params = {
    boutiqueday,
    setBoutiqueDay,
    setRedirectToCaisse,
    createAlert
  }
  const closeBoutiqueDay = closeBoutiqueDayBase(params);
  const addComment = addCommentBase(params);
  const loadData = () => {
    genericDetailLoader({
      setLoading,
      setItem: setBoutiqueDay,
      params: {
        boutiqueday_id,
        boutique,
      },
      url: 'boutiquedays/fetch-detail',
      key: 'boutiqueday',
      createAlert
    })
  }

  useEffect(loadData, [])

  if (loading) {
    return <LoadingSpinner />
  }

  if (redirectToCaisse) {
    return <Redirect to={`/boutiquedays/${boutiqueday_id}/caisse`} />
  }

  const breadcrumbItems = breadcrumbs(boutiqueday);

  const statusTitle = boutiqueday.closedAt
    ? 'Clôturée'
    : 'Ouverte';

  const eventsList = [
    {
      title: 'Ouverture',
      date: boutiqueday.openedAt,
      icon: 'play'
    },
    ...(boutiqueday.closedAt ? [{
      title: 'Clôture',
      date: boutiqueday.closedAt,
      icon: 'stop'
    }] : [])
  ];

  const commentsProps = {
    comments: boutiqueday.comments,
    addComment,
    showComments: true,
    allowAddComment: true
  }

  const {
    caDisplay,
    ordersDisplay,
    articlesDisplay,
    panierMoyenDisplay
  } = getDisplays(boutiqueday);

  const allowCloseBoutiqueday = !!boutiqueday.openedAt
    && !boutiqueday.globalorder_id;

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Row className='mb-4'>
          <Col xs={6}>
            <div className='text-md bold'>
              {formatDate(boutiqueday.openedAt, 'dddd DD MMMM YYYY')}
            </div>
            <div className={boutiqueday.globalorder_id ? 'text-success' : 'text-muted'}>
              Globale: &nbsp;
              {boutiqueday.globalorder_id ? (
                <IconText icon='check' text='Oui' />
              ) : (
                <IconText icon='times' text='Non' />
              )}
            </div>
          </Col>
          <Col xs={6} className='text-right'>
            <DetailUpperRight
              statusName={statusTitle}
              commentsProps={commentsProps}
              eventsList={eventsList}
            />
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={3} className='text-center'>
            <ItemDetailDisplayFrame
              title='Commandes'
              display={ordersDisplay}
            />
          </Col>
          <Col sm={3} className='text-center'>
            <ItemDetailDisplayFrame
              title='Articles'
              display={articlesDisplay}
            />
          </Col>
          <Col sm={3} className='text-center'>
            <ItemDetailDisplayFrame
              title='Ventes TTC'
              display={caDisplay}
            />
          </Col>
          <Col sm={3} className='text-center'>
            <ItemDetailDisplayFrame
              title='Panier moyen'
              display={panierMoyenDisplay}
            />
          </Col>
        </Row>

        {allowCloseBoutiqueday && (
          <div className='mb-5'>
            <ValidationButton
              onValidation={closeBoutiqueDay}
              buttonProps={{ variant: 'warning' }}
              buttonClasses='float-right'
              mustConfirm={true}
              confirmationText='Etes-vous sûr de vouloir clôturer cette vente ?'
              buttonText='Clôturer la vente'
            />
          </div>
        )}
      </Container>
    </>
  )
}