import React from 'react';
import { useDispatch } from 'react-redux';
import { ErrorPage as ErrorPageBase } from '@kelbongoo/shared-client-react-v2/dist/components/ErrorPage';
import { clearStoreErrors } from '../../../actions';

export const ErrorPage = () => {

  const dispatch = useDispatch();
  const handleClearStoreErrors = () => {
    console.log('10')
    dispatch(clearStoreErrors())
  }
  console.log('13')
  return <ErrorPageBase clearStoreErrors={handleClearStoreErrors} />
}