import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Alert from 'react-bootstrap/Alert';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';


export const HeaderItemsLeft = () => {

  const { currentBoutique } = useSelector(state => state.boutiques);
  const salesHeader = (
    <Icon name='euro-sign' className='text-3xl'/>
  )
  const stockHeader = (
    <Icon name='wine-bottle' className='text-3xl'/>
  )
  const adminHeader = (
    <Icon name='cog' className='text-3xl' />
  )
  
  return (
    <>
      <Nav.Item style={{display: 'inline-block'}}>
        <Alert variant='warning' style={{marginBottom: '0'}}>

          <div 
            data-testid='current-boutique' 
            style={{display: 'inline-block'}}
          >
            {currentBoutique}
          </div>
        </Alert>
      </Nav.Item>
      <NavDropdown title={salesHeader} id="sales-menu" className='color-inherit'>
        <NavDropdown.Item as={Link} to='/boutiquedays' className='text-xl py-3'>Ventes</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/nrorders' className='text-xl py-3'>Commandes NR</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/caisses' className='text-xl py-3'>Caisses</NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title={stockHeader} id="stock-menu" className='color-inherit'>
        <NavDropdown.Item as={Link} to='/stock' className='text-xl py-3'>Stock</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/stock/restocks' className='text-xl py-3'>Réappros stock</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/stock/inventories' className='text-xl py-3'>Inventaires stock</NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title={adminHeader} id="admin-menu" className='color-inherit'>
        <NavDropdown.Item as={Link} to='/tasks' className='text-xl py-3'>Tâches</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/coldchain' className='text-xl py-3'>Chaîne du froid</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/equipments' className='text-xl py-3'>Equipements</NavDropdown.Item>
        <NavDropdown.Item as={Link} to='/params' className='text-xl py-3'>Paramètres</NavDropdown.Item>
      </NavDropdown>
    </>
  )
}