import React, { useEffect } from 'react';
import { BrowserRouter, HashRouter, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ProtectedRoute } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { NotFound } from '@kelbongoo/shared-client-react-v2/dist/components/NotFound';
import { ErrorPage } from './components/ui/ErrorPage';
import { fetchBoutiques } from './actions';
import { Login } from './components/pages/Login';
import { ForgotPassword } from './components/pages/ForgotPassword';

import { BoutiqueDaysHome } from './components/pages/BoutiqueDaysHome';
import { BoutiqueDaysDetail } from './components/pages/BoutiqueDaysDetail';
import { BoutiqueDaysNew } from './components/pages/BoutiqueDaysNew';
import { BoutiqueDaysDetailCaisse } from './components/pages/BoutiqueDaysDetailCaisse';
import { BoutiqueDaysDetailOrders } from './components/pages/BoutiqueDaysDetailOrders';
import { BoutiqueDaysDetailOrdersNew } from './components/pages/BoutiqueDaysDetailOrdersNew';
import { BoutiqueDaysDetailOrdersDetail } from './components/pages/BoutiqueDaysDetailOrdersDetail';

import { Planning } from './components/pages/Planning';
import { ColdChain } from './components/pages/ColdChain';
import { CaissesHome } from './components/pages/CaissesHome';

import { NROrdersHome } from './components/pages/NROrdersHome';
import { NROrdersDetail } from './components/pages/NROrdersDetail';

import { InventoriesHome } from './components/pages/InventoriesHome';
import { InventoriesDetail } from './components/pages/InventoriesDetail';

import { StockHome } from './components/pages/StockHome';
import { StockDetail } from './components/pages/StockDetail';
import { StockDetailEvents } from './components/pages/StockDetailEvents';
import { StockDetailComments } from './components/pages/StockDetailComments';
import { StockRestocksHome } from './components/pages/StockRestocksHome';
import { StockRestocksDetail } from './components/pages/StockRestocksDetail';
import { StockRestocksNew } from './components/pages/StockRestocksNew';

import { EquipmentsHome } from './components/pages/EquipmentsHome';
import { EquipmentsNew } from './components/pages/EquipmentsNew';
import { EquipmentsDetail } from './components/pages/EquipmentsDetail';

import { Header } from './components/ui/Header';
import { Home } from './components/pages/Home';
import { Tasks } from './components/pages/Tasks';
import './icons';
import './App.css';


function App() {

  let Router;
  if (window.cordova) {
    Router = HashRouter;
  } else {
    Router = BrowserRouter;
  }

  const isMobile = !!window.cordova;

  const dispatch = useDispatch();
  const { loggedIn } = useSelector(state => state.currentUser);
  const { currentBoutique } = useSelector(state => state.boutiques);
  const params = {
    dispatch,
    currentBoutique
  }

  useEffect(() => {
    dispatch(fetchBoutiques());
  }, [dispatch])


  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/forgotpassword' component={ForgotPassword} />

        <ProtectedRoute exact path='/' component={Home} />

        <ProtectedRoute exact path='/boutiquedays' component={BoutiqueDaysHome} />
        <ProtectedRoute exact path='/boutiquedays/new' component={BoutiqueDaysNew} />
        <ProtectedRoute exact path='/boutiquedays/:boutiqueday_id' component={BoutiqueDaysDetail} />
        <ProtectedRoute exact path='/boutiquedays/:boutiqueday_id/caisse' component={BoutiqueDaysDetailCaisse} />
        <ProtectedRoute exact path='/boutiquedays/:boutiqueday_id/orders' component={BoutiqueDaysDetailOrders} />
        <ProtectedRoute exact path='/boutiquedays/:boutiqueday_id/orders/new' component={BoutiqueDaysDetailOrdersNew} />
        <ProtectedRoute exact path='/boutiquedays/:boutiqueday_id/orders/:boutiqueorder_id' component={BoutiqueDaysDetailOrdersDetail} />

        <ProtectedRoute exact path='/caisses' component={CaissesHome} />

        <ProtectedRoute exact path='/equipments' component={EquipmentsHome} />
        <ProtectedRoute exact path='/equipments/new' component={EquipmentsNew} />
        <ProtectedRoute exact path='/equipments/:equipment_id' component={EquipmentsDetail} />

        <ProtectedRoute exact path='/nrorders' component={NROrdersHome} />
        <ProtectedRoute exact path='/nrorders/:order_id' component={NROrdersDetail} />


        <ProtectedRoute exact path='/stock' component={StockHome} />

        <ProtectedRoute exact path='/stock/restocks' component={StockRestocksHome} />
        <ProtectedRoute exact path='/stock/restocks/new' component={StockRestocksNew} />
        <ProtectedRoute exact path='/stock/restocks/:restock_id' component={StockRestocksDetail} />

        <ProtectedRoute exact path='/stock/inventories' component={InventoriesHome} />
        <ProtectedRoute exact path='/stock/inventories/:inventory_id' component={InventoriesDetail} />

        <ProtectedRoute exact path='/stock/:producerproduct_id' component={StockDetail} />
        <ProtectedRoute exact path='/stock/:producerproduct_id/events' component={StockDetailEvents} />
        <ProtectedRoute exact path='/stock/:producerproduct_id/comments' component={StockDetailComments} />

        <ProtectedRoute exact path='/coldchain' component={ColdChain} />
        <ProtectedRoute exact path='/planning' component={Planning} />

        <ProtectedRoute exact path='/tasks/:date' component={Tasks} />
        <ProtectedRoute exact path='/error' component={ErrorPage} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
