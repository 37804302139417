import cloneDeep from "lodash/cloneDeep";

export const receiveTransfer = ({
  transfers,
  transfersData,
  setTransfers,
  setTransfersData
}) => transfer_id => {

  const updatedTransfers = cloneDeep(transfers);
  const updatedTransfersData = cloneDeep(transfersData);

  let dataIndex = updatedTransfersData.findIndex(
    t => t.transfer_id === transfer_id
  );
  const transfersIndex = updatedTransfers.findIndex(
    t => t._id === transfer_id
  );

  if(dataIndex === -1){

    updatedTransfersData.push({
      transfer_id: updatedTransfers[transfersIndex]._id,
      items: updatedTransfers[transfersIndex].items
    });

    dataIndex = updatedTransfersData.length - 1;
  }

  updatedTransfers[transfersIndex].isReceived = () => true;
  updatedTransfersData[dataIndex].isReceived = () => true;


  setTransfersData(updatedTransfersData);
  setTransfers(updatedTransfers);

  return Promise.resolve();
};