import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { ErrorBoundary as ErrorBoundaryBase } from '@kelbongoo/shared-client-react-v2/dist/components/ErrorBoundary';
import { HeaderItemsLeft } from '../HeaderItemsLeft';
import 'bootstrap/dist/css/bootstrap.min.css';

const ErrorBoundary = ErrorBoundaryBase({});

export const Header = () => {

	const dispatch = useDispatch();

  return (
		<ErrorBoundary>
			<HeaderBase 
				HeaderItemsLeft={HeaderItemsLeft}
				dispatch={dispatch}
				useSelector={useSelector}
				Link={Link}
			/>
		</ErrorBoundary>
	)
}