import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';

export const breadcrumbs = (boutiqueday) => [
  {
    title: 'Ventes',
    type: 'breadcrumb',
    href: '/boutiquedays'
  },{ 
    type: 'breadcrumb', 
    title: formatDate(boutiqueday.openedAt, 'ddd DD MMM YYYY'),
    href: `/boutiquedays/${boutiqueday._id}`
  },
  { 
    type: 'breadcrumb', 
    title: 'Caisse'
  }
];