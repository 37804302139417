export const buildFilters = () => {

  const daterangeFilter = {
    keys: ['date']
  }
  const quickFilter = {
    value: 'all',
    keys: ['doneAt'],
    items: [
      { value: 'all', name: 'Tout' },
      { value: 'done', name: 'Fini' },
      { value: 'not-done', name: 'Pas fini' },
    ],
    valueMapping(value){
      return {
        'all': {},
        'done': { doneAt: { $exists: true }},
        'not-done': { doneAt: { $exists: false }},
      }[value]
    }
  }

  return {
    daterangeFilter,
    quickFilter
  }
}