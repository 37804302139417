import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const openCaisse = ({
  setCaisseDay,
  createAlert
}) => caisseday => () => {

  const caller = wrapClientApiCall({
    url: 'caissedays/open-caisse'
  });

  return caller({ 
    params: { 
      caisseday_id: caisseday._id 
    }
  })
    .then(caisseday => setCaisseDay(caisseday))
    .catch(err => createAlert({ message: err.message }));
};