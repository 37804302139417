import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { formatEvents } from '../../../../utils';

export const refreshEvents = ({
  boutique,
  createAlert
}) => ({ start, end }) => {

  const caller = wrapClientApiCall({
    url: 'boutiques/get-daterange-events'
  })

  return caller({
    params: {
      boutique, 
      start, 
      end,
      application: 'boutiques'
    }
  })
    .then(events => formatEvents(events))
    .catch(err => createAlert({ message: err.message }))
};