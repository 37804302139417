import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const runConsumerSearch = ({
  createAlert
}) => (search) => {

  const caller = wrapClientApiCall({
    url: 'consumers/fetch'
  });

  return caller({
    params: {
      email: search,
      lastname: search
    },
    options: {
      fields: { firstname: 1, lastname: 1, email: 1 },
      limit: 8,
      sort: { lastname: 1, firstname: 1 }
    }
  })
    .then(({ items }) => items)
    .catch(err => createAlert({ message: err.message }));
};