import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

const fields = { producer_name: 1, product_name: 1, producerproduct_id: 1, conditionnement: 1 }
const sort = { producer_name: 1, product_name: 1 }

export const runSearch = ({
  boutiquerestock,
  boutique,
  createAlert
}) => ({ usedReferenceIds: usedIds, search, extra }) => {

  const caller = wrapClientApiCall({
    url: 'boutiqueproducerproducts/fetch'
  })

  return caller({
    params: {
      boutique,
      producerproduct_id: { $nin: usedIds },
      product_name: search,
      producer_id: boutiquerestock.producer_id
    },
    options: {
      limit: 8, 
      fields, 
      sort
    }
  })
    .then(({ items }) => items)
    .catch(err => createAlert({ message: err.message }));
};