import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addEquipment = ({
  currentBoutique,
  createAlert,
  refreshData
}) => equipment_id => () => {

  const caller = wrapClientApiCall({
    url: 'equipments/add-item'
  });

  return caller({
    params: {
      boutique: currentBoutique,
      equipment_id
    }
  })
    .then(() => {
      createAlert({ type: 'success' })
      return refreshData({ page: 1, currentQuery: {} });
    })
    .catch(err => createAlert({ message: err.message }));
};