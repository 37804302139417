import model from '@kelbongoo/shared-methods/caissetransfers/model';
import collection from '@kelbongoo/shared-methods/caissetransfers/collection';

class CaisseTransfer {

  constructor(data){

    Object.assign(this, data);
  }
}

Object.assign(
  CaisseTransfer.prototype,
  model
);

Object.assign(
  CaisseTransfer,
  collection
);

export {
  CaisseTransfer
}