import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash/cloneDeep";

export const receiveTransfer = ({
  transfers,
  setTransfers,
  createAlert
}) => caisseday => transfer_id => {

  const caller = wrapClientApiCall({
    url: 'caissedays/receive-transfer'
  });

  return caller({ 
    params: { 
      caisseday_id: caisseday._id, 
      transfer_id 
    }
  })
    .then(updatedTransfer => {

      const updatedTransfers = cloneDeep(transfers);

      let ind = updatedTransfers.findIndex(
        t => t._id === transfer_id
      );
      updatedTransfers[ind] = updatedTransfer;
      return setTransfers(updatedTransfers);
    })
    .catch(err => createAlert({ message: err.message }));
};