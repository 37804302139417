import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';
import cloneDeep from 'lodash.clonedeep';

export const addComment = ({
  taskinstances,
  setTaskInstances,
  createAlert
}) => (taskinstance_id) =>  (comment) => {

  const caller = wrapClientApiCall({
    url: 'taskinstances/add-comment'
  });

  return caller({
    params: {
      taskinstance_id, 
      comment,
      userId: '2W8X2SaMhytesy4ne'
    }
  })
    .then(({ comments }) => {

      const updatedTaskInstances = cloneDeep(taskinstances);

      const index = updatedTaskInstances.findIndex(
        t => t._id === taskinstance_id
      )
      updatedTaskInstances[index].comments = comments;
      
      setTaskInstances(updatedTaskInstances);
    })
    .catch(err => createAlert({ message: err.message }));
};