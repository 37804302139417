import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { refreshData } from './refreshData';


export const validateInventory = ({
  boutique,
  inventory,
  setInventory,
  createAlert
}) => () => {

  const caller = wrapClientApiCall({
    url: 'boutiques/validate-inventory'
  })

  return caller({
    params: {
      boutique,
      inventory_id: inventory._id
    }
  })
    .then(() => refreshData({
      boutique,
      inventory,
      setInventory,
      createAlert
    }))
    .catch(err => createAlert({ message: err.message }));
};