import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const fetchConsumerCredit = ({
  createAlert
}) => (consumer_id) => {

  const caller = wrapClientApiCall({
    url: 'consumers/fetch-detail'
  });

  return caller({
    params: {
      consumer_id,
      userId: '2W8X2SaMhytesy4ne', // WARN

    }
  })
    .catch(err => createAlert({ message: err.message }));
};