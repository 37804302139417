import model from '@kelbongoo/shared-methods/boutiquedays/model';
import collection from '@kelbongoo/shared-methods/boutiquedays/collection';

class BoutiqueDay {

  constructor(data){

    Object.assign(this, data);
  }

  allowAddComment(){
    return true;
  }
}

Object.assign(
  BoutiqueDay.prototype,
  model
);

Object.assign(
  BoutiqueDay,
  collection
);

export {
  BoutiqueDay
}