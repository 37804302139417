import cloneDeep from "lodash/cloneDeep";

export const updateTransferItems = ({
  transfers,
  transfersData,
  setTransfers,
  setTransfersData
}) => (transfer_id, items) => {

  const updatedTransfersData = cloneDeep(transfersData);
  const updatedTransfers = cloneDeep(transfers);

  let dataIndex = transfersData.findIndex(
    t => t.transfer_id === transfer_id
  );
  const transfersIndex = updatedTransfers.findIndex(
    t => t._id === transfer_id
  );

  if(dataIndex === -1){

    updatedTransfersData.push({
      transfer_id: updatedTransfers[transfersIndex]._id,
      items: updatedTransfers[transfersIndex].items
    });

    dataIndex = updatedTransfersData.length - 1;
  }

  updatedTransfersData[dataIndex].items = items;

  updatedTransfers[transfersIndex].items = items;

  setTransfersData(updatedTransfersData);

  setTransfers(updatedTransfers);
  
  return Promise.resolve();
};