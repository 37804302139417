export const getFilters = () => {

  const quickFilter = {
    keys: ['active'],
    value: true,
    items: [
      { value: 'all', name: 'Tous les produits' },
      { value: false, name: 'Produits inactifs' },
      { value: true, name: 'Produits actifs', selected: true }
    ],
    valueMapping(value){
      if(value === 'all') return {};
      if(value === false) return { active: false };
      if(value === true) return { active: true };
    }
  };
  const searchFilter = {
    keys: ['product_name'],
    placeholder: 'Nom du produit'
  }
  const selectFilters = [
    {
      allOption: 'Tous les producteurs',
      options: [], // fix !!!
      keys: ['producer_id'],
      valueMapping(value){
        if(value === 'all'){
          return {};
        }
        return { producer_id: value };
      },
      selectFilterId: 'producerId'
    },
    {
      options: [
        { value: 'stock', name: 'Stock', selected: true },
        { value: 'preordered', name: 'Non-fragile / Précommandés' }
      ],
      keys: ['preordered'],
      valueMapping(value){
        if(value === 'stock'){
          return { preordered: false };
        }
        else if (value === 'preordered') {
          return { preordered: true };
        }
      },
      selectFilterId: 'productType'
    }
  ];

  return {
    searchFilter,
    quickFilter,
    selectFilters
  }
}