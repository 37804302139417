import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash.clonedeep";

export const addComment = ({
  order,
  setOrder,
  createAlert
}) => comment => {

  const caller = wrapClientApiCall({
    url: 'orders/add-comment'
  });

  return caller({
    params: {
      order_id: order._id, 
      comment
    }
  })
    .then(({ comments }) => {

      const updatedOrder = cloneDeep(order);
      updatedOrder.comments = comments;
      setOrder(updatedOrder);
})
    .catch(err => createAlert({ message: err.message }));
};