export const breadcrumbs = () => [{
  title: 'Stock',
  type: 'breadcrumb'
},{
  title: 'Ajouter un produit',
  href: '/stock/new',
  type: 'menu',
  icon: 'plus'
},{
  type: 'menu',
  divider: true
},{
  title: 'Paramètrage',
  href: '/stock/params',
  type: 'menu',
  icon: 'cog'
}];