import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const saveCloseData = ({
  fetchData,
  createAlert
}) => caisseday => data => {

  const caller = wrapClientApiCall({
    url: 'caissedays/save-close-data'
  })

  return caller({ 
    params: { 
      caisseday_id: caisseday._id, 
      data 
    }
  })
    .then(() => fetchData())
    .catch(err => createAlert({ message: err.message }));
};