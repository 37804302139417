import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container';

import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { EquipmentsDetailView } from '@kelbongoo/shared-client-react-v2/dist/components/EquipmentsDetailView';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import {
  addComment as addCommentBase,
  updateEquipmentStatus as updateEquipmentStatusBase
} from './actions';


export const EquipmentsDetail = () => {

  const dispatch = useDispatch();
  const { equipment_id } = useParams();
  const { currentBoutique } = useSelector(state => state.boutique);
  const [equipment, setEquipment] = useState();
  const [loading, setLoading] = useState(true);
  const createAlert = d => dispatch(createAlertBase(d))

  const params = {
    equipment,
    setEquipment,
    createAlert
  }
  const addComment = addCommentBase(params);
  const updateEquipmentStatus = updateEquipmentStatusBase(params);

   const genericDetailLoader = () => {} // TODO

  useEffect(() => {
    genericDetailLoader({
      setLoading,
      setItem: setEquipment,
      params: {
        equipment_id,
        boutique: currentBoutique,
      },
      url: 'equipments/fetch-detail',
      key: 'equipment',
      createAlert
    })
  }, [])

  const breadcrumbBarItems = [];
  // const breadcrumbBarItems = breadcrumbs();


  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbBarItems} Link={Link} />
      <Container fluid>

        <EquipmentsDetailView
          equipment={equipment}
          receivedByUser={{}}
          allowEdit={true}
          possibleEquipmentStatuses={[]}
          updateEquipment={updateEquipmentStatus}
          addComment={addComment}
        />
      </Container>
    </>
  )
}