
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { CommentsHomeView } from '@kelbongoo/shared-client-react-v2/dist/components/CommentsHomeView';
import { breadcrumbs } from './helpers';


export const StockDetailComments = () => {

  const breadcrumbItems = breadcrumbs();
  const dispatch = useDispatch();

  const addComment = () => {}

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <CommentsHomeView
          url='comments/fetch'
          dispatch={dispatch}
          addComment={addComment}
          allowAddComment={true}
          allowPhotos={true}
        />
      </Container>
    </>
  )
}