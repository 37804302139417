import { formatTransferGroup } from './formatTransferGroup';
import { formatTasks } from './formatTasks';
import { formatPreparation } from './formatPreparation';

export const formatEvents = events => {

  return Object.keys(events).reduce((acc,key) => {

		switch(key){
			case 'taskinstances': acc = acc.concat(formatTasks(events[key], { url: '/tasks' })); break;
			default: break;
		}
		return acc;
	}, [])
};