import React, { useState, useEffect } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';

import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { BoutiqueOrdersDetail } from '@kelbongoo/shared-client-react-v2/dist/components/BoutiqueOrdersDetail';

import { genericDetailLoader } from '../../../utils';
import { breadcrumbs, getAmountPaid } from './helpers';
import {
  addPayment as addPaymentBase,
  fetchConsumerCredit as fetchConsumerCreditBase,
  runExtraSearch as runExtraSearchBase,
  runConsumerSearch as runConsumerSearchBase,
  runSearch as runSearchBase
} from './actions';


export const BoutiqueDaysDetailOrdersNew = () => {

  const dispatch = useDispatch();

  const { boutiqueday_id } = useParams();
  const { currentBoutique, boutiques } = useSelector(state => state.boutiques);

  const [createdBoutiqueOrderId, setCreatedBoutiqueOrderId] = useState(true);
  const [boutiqueday, setBoutiqueDay] = useState();
  const [redirectToDetail, setRedirectToDetail] = useState(false);

  const [items, setItems] = useState();
  const [reduced, setReduced] = useState();

  const boutique = boutiques.find(b => b.code === currentBoutique)

  const createAlert = d => dispatch(createAlertBase(d))

  const loadBoutiqueDay = () => genericDetailLoader({
    setItem: setBoutiqueDay,
    params: {
      boutiqueday_id,
      boutique,
    },
    url: 'boutiquedays/fetch-detail',
    key: 'boutiqueday',
    createAlert
  });

  useEffect(() => {
    loadBoutiqueDay();
  }, [])

  if (!boutiqueday) {
    return <LoadingSpinner />
  }
  if (redirectToDetail) {
    return <Redirect to={`/boutiquedays/${boutiqueday_id}/orders/${createdBoutiqueOrderId}`} />
  }

  const breadcrumbItems = breadcrumbs(boutiqueday);

  const addPayment = addPaymentBase({
    setRedirectToDetail,
    setCreatedBoutiqueOrderId,
    boutiqueday_id,
    currentBoutique,
    createAlert
  });

  const fetchConsumerCredit = fetchConsumerCreditBase({ createAlert });

  const runConsumerSearch = runConsumerSearchBase({ createAlert });

  const runExtraSearch = runExtraSearchBase({ createAlert });

  const runSearch = runSearchBase({ createAlert, currentBoutique });

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />

      <Container fluid>

        {/* <BoutiqueOrdersDetail
          addPayment={addPayment}
          allowAddPayment={true}
          extraPlaceholderText='Choisir un producteur...'
          extraType='dropdown_input'
          fetchConsumerCredit={fetchConsumerCredit}
          getAmountPaid={BoutiqueOrder.getAmountPaid}
          runConsumerSearch={runConsumerSearch}
          runExtraSearch={runExtraSearch}
          runSearch={runSearch}
          showClearOrder={true}
        /> */}

        <BoutiqueOrdersDetail
          /**shared props**/
          items={items}
          reduced={reduced}

          /** payment params*/
          allowAddPayment={true}
          getAmountPaid={getAmountPaid}
          // printReceipt={printReceipt}
          // showPrintReceipt={isMobile}
          addPayment={addPayment}
          showCancelPayment={false} // can't cancel a new order

          /**add item params */
          runSearch={runSearch}
          // setItem=''
          setItems={setItems}
          setReduced={setReduced}
          runExtraSearch={runExtraSearch}
          // placeholderText=''
          allowSellByWeight={true}

          /**consumer search params */
          fetchConsumerCredit={fetchConsumerCredit}
          runConsumerSearch={runConsumerSearch}

          /**comments params */
          allowAddComment={false}
          // addComment=''

          showClearOrder={true}

          /** upper left params */
          Link={Link}
          noConsumerLink={true}
          showOrderBoutique={false}
        />

      </Container>

    </>
  )
}