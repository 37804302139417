import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const fetchData = ({
  boutiqueday_id,
  createAlert,
  setCaisseDay,
  setTransfers
}) => () => {

  const caller = wrapClientApiCall({
    url: 'boutiquedays/get-caisseday'
  })

  return caller({ 
    params: {
      boutiqueday_id
    }
  })
    .then(data => {

      if(!data.caisseday){
        return;
      }
      setCaisseDay(data.caisseday)
      setTransfers(data.transfers)
    })
    .catch(err => createAlert({ message: err.message }));
};