import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash.clonedeep";

export const addComment = ({
  boutique,
  inventory,
  setInventory,
  createAlert
}) => comment => {

  const caller = wrapClientApiCall({
    url: 'inventories/add-comment'
  });

  return caller({
    params: {
      inventory_id: inventory._id,
      comment
    }
  })
    .then(({ comments }) => {

      const updatedInventory = cloneDeep(inventory);
      updatedInventory.comments = comments;
      setInventory(updatedInventory);
    })
    .catch(err => createAlert({ message: err.message }));
};