import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const closeCaisse = ({
  createAlert,
  setCaisseDay
}) => caisseday => data => {

  const caller = wrapClientApiCall({
    url: 'caissedays/close-caisse'
  });

  return caller({ 
    params: { 
      caisseday_id: caisseday._id, 
      data 
    }
  })
    .then(caisseday => {
      setCaisseDay(caisseday);
      createAlert({ type: 'success' });
    })
    .catch(err => createAlert({ message: err.message }));
};