import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container'

import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import {
  Datatable,
  updateQuery as updateQueryBase,
  refreshData as refreshDataBase
} from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';

import { genericDetailLoader } from '../../../utils';
import { BoutiqueOrder } from '../../../models/BoutiqueOrder';
import {
  breadcrumbs,
  buildFilters
} from './helpers';
import { BoutiqueDaysDetailOrdersRow } from './BoutiqueDaysDetailOrdersRow';


export const BoutiqueDaysDetailOrders = () => {

  const dispatch = useDispatch();

  const { boutiqueday_id } = useParams();

  const { daterangeFilter, quickFilter } = buildFilters();
  // WARN where does comment and preorder args come from?
  const defaultQuery = Object.assign(quickFilter.valueMapping(quickFilter.defaultValue))

  const { currentBoutique } = useSelector(state => state.boutiques);
  const [boutiqueday, setBoutiqueDay] = useState();
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState(defaultQuery);
  const [page, setPage] = useState(1);


  const createAlert = d => dispatch(createAlertBase(d))

  const updateQuery = updateQueryBase({ setCurrentQuery, setPage, currentQuery })

  const refreshData = refreshDataBase({
    url: 'boutiqueorders/fetch',
    setItems,
    setCount,
    createAlert,
    setLoading,
    selectorAddons: {
      boutique: currentBoutique,
      boutiqueday_id
    },
    selectFields: {
      createdAt: 1,
      paidAt: 1,
      items: 1,
      reduced: 1,
      consumer_id: 1
    },
    sortFields: { createdAt: -1 }
  })

  const loadGenericData = () => {
    genericDetailLoader({
      setLoading: setInitLoading,
      setItem: setBoutiqueDay,
      params: {
        boutiqueday_id,
        boutique: currentBoutique,
      },
      url: 'boutiquedays/fetch-detail',
      key: 'boutiqueday',
      createAlert
    })
  }

  const loadData = () => {
    if (!currentQuery) return;
    // WARN where does comment and preorder args come from?
    refreshData({
      currentQuery: Object.assign(currentQuery, { comment: undefined, preordered: undefined }),
      page,
      docsPerPage
    })
  }

  const breadcrumbItems = boutiqueday && breadcrumbs(boutiqueday);

  useEffect(() => { setCurrentQuery(defaultQuery) }, [])
  useEffect(loadGenericData, [])
  useEffect(loadData, [currentQuery, page, docsPerPage]);

  if (initLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Datatable
          columns={[
            { title: 'Heure de passage' },
            // { title: 'Tag' },
            { title: 'Nb d\'articles' },
            { title: 'Total' }
          ]}
          DatatableRow={BoutiqueDaysDetailOrdersRow}
          items={items.map(i => new BoutiqueOrder({ ...i, boutiqueday_id }))}
          count={count}
          loading={loading}
          updateQuery={updateQuery}
          setDocsPerPage={setDocsPerPage}
          quickFilter={quickFilter}
          // daterangeFilter={daterangeFilter} // broken
          useSelector={useSelector}
          dispatch={dispatch}
          showPagination={true}
          currentPage={page}
          updatePage={setPage}
        />
      </Container>
    </>
  )
}