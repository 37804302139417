
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container'
import { PlanningHomeView } from '@kelbongoo/shared-client-react-v2/dist/components/PlanningHomeView';
import { refreshEvents } from './actions';


export const Planning = () => {

  const [events, setEvents] = useState([]);
  const { currentBoutique } = useSelector(state => state.boutiques);
  const onRefreshEvents = refreshEvents({
    setEvents,
    boutique: currentBoutique
  })

  return (
    <Container fluid>
      <PlanningHomeView
        refreshEvents={onRefreshEvents}
        events={events}
      />
    </Container>
  )
}