import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import pick from 'lodash.pick';

export const createRestock = ({
  boutique,
  createAlert,
  selectedProducer
}) => items => {

  const caller = wrapClientApiCall({
    url: 'boutiques/create-boutiquerestock'
  });

  return caller({
    params: {
      items: items.map(i => pick(i, ['producerproduct_id', 'quantity_ordered'])),
      boutique,
      producer_id: selectedProducer
    }
  })
    .then(({ boutiquerestock_id }) => boutiquerestock_id)
    .catch(err => createAlert({ message: err.message }));
};