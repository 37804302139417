import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { EquipmentsHomeView } from '@kelbongoo/shared-client-react-v2/dist/components/EquipmentsHomeView';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';

export const EquipmentsHome = () => {

  const dispatch = useDispatch();
  const { currentBoutique } = useSelector(state => state.boutiques);
  // const breadcrumbBarItems = breadcrumbs();
  const breadcrumbBarItems = []; // TODO

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbBarItems} Link={Link} />
      <Container fluid>

        <EquipmentsHomeView
          contextKey='boutique'
          contextValue={currentBoutique}
          dispatch={dispatch}
          url='equipments/fetch'
          fetchEquipmentTypes={() => ([])}
          isAdmin={false}
        />
      </Container>
    </>
  )
}