import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const updateRestockItems = ({
  boutique,
  boutiquerestock,
  createAlert,
  setRestock
}) => items => {

  const caller = wrapClientApiCall({
    url: 'boutiques/update-boutiquerestock-items'
  })

  return caller({
    params: {
      boutique,
      boutiquerestock_id: boutiquerestock._id,
      items
    }
  })
    .then(({ updatedDoc }) => {
      
      createAlert({ type: 'success' })
      
      setRestock(updatedDoc)
    })
    .catch(err => createAlert({ message: err.message }));
};