import React from 'react';

export const ItemDetailDisplayFrame = ({
  title,
  display,
  DynamicComponent
}) => {

  return (
    <div 
      className='clearfix mt-2' 
      style={{border: '1px solid #efefef', borderRadius : '5px', padding : '20px 10px'}}
    >
      <div>
        <span className='text-sm text-muted'>
          {title}
        </span>
        <br/>
        <span className='text-2xl'>
          {DynamicComponent ? (
            <DynamicComponent display={display} />
          ) : (
            <>{display}</>
          )}
        </span>
      </div>
    </div>
  )
}