import React from 'react';
import { Link } from 'react-router-dom';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';


export const InventoriesHomeRow = ({
  _id,
  tag,
  date,
  doneAt
}) => {

  return (
    <tr>
      <td>
        <Link to={`/inventories/${_id}`}>
          {formatDate(date, 'dddd DD MMMM YYYY')}
        </Link>
      </td>
      <td>{tag}</td>
      <td className='text-center'>
        {doneAt ? (
          <>
            <Icon name='check' className='text-success' textRight />
            <span>{formatDate(date, 'DD-MM-YYYY')}</span>
          </>
        ) : (
          <>
            <Icon name='clock' className='text-muted' textRight />
            <span>Pas encore</span>
          </>
        )}
      </td>
    </tr>
  )
}