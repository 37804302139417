import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { ValidationButton } from '@kelbongoo/shared-client-react-v2/dist/components/ValidationButton';
import { IconText } from '@kelbongoo/shared-client-react-v2/dist/components/IconText';


export const NROrdersHomeRow = ({ item }) => {

  return (
    <tr className=''>
      <td className='vertical-center'>
        <Link to={`/nrorders/${item._id}`}>
          {item.consumer_name || item._id}
        </Link>
      </td>
      <td className='vertical-center bold text-xl'>
        {item.nr_bacs && item.nr_bacs.join(', ')}
      </td>
      <td className='vertical-center'>
        {formatDate(item.distribution_date, 'dddd DD MMMM')}
      </td>
      <td className='text-center vertical-center'>
        {item.paidAt ? (
          <ValidationButton
            buttonProps={{ size: 'sm', variant: 'success' }}
            buttonIcon='ok'
            onValidation={item.markPickedUp}
            mustConfirm={true}
            confirmationText='Etes-vous sûr de vouloir marquer cette commande recupérée ?'
          />
        ) : (
          <Button as={Link} href={`/nrorders/${item._id}`} variant='danger' size='sm'>
            <IconText icon='euro-sign' text='Encaisser' />
          </Button>
        )}
      </td>
    </tr>
  )
}