import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const closeBoutiqueDay = ({
  boutiqueday,
  createAlert,
  setRedirectToCaisse
}) => () => {

  const caller = wrapClientApiCall({
    url: 'boutiquedays/close'
  });

  return caller({
    params: {
      boutiqueday_id: boutiqueday._id
    }
  })
    .then(() => setRedirectToCaisse(true))
    .catch(err => createAlert({ message: err.message }));
};