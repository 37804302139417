import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container';

import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { CaissesHistoryView } from '@kelbongoo/shared-client-react-v2/dist/components/CaissesHistoryView';

import { breadcrumbs } from './helpers';

export const CaissesHome = () => {

  const dispatch = useDispatch();
  const { currentBoutique } = useSelector(state => state.boutiques);
  const breadcrumbBarItems = breadcrumbs();

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbBarItems} Link={Link} />
      <Container fluid>
        <CaissesHistoryView
          contextKey='boutique'
          contextValue={currentBoutique}
          dispatch={dispatch}
          url='caissedays/fetch'
          update
        />
      </Container>
    </>
  )
}