
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { RestocksDetailView } from '@kelbongoo/shared-client-react-v2/dist/components/RestocksDetailView';
import { BoutiqueRestock } from '../../../models/BoutiqueRestock';
import { StockRestocksNewSelectProducer } from './StockRestocksNewSelectProducer';
import { StockRestocksNewExistingRestock } from './StockRestocksNewExistingRestock';
import { breadcrumbs } from './helpers';
import {
  createRestock as createRestockBase,
  runSearch as runSearchBase
} from './actions';

const formatSuggestion = suggestion => ({
  value: suggestion.producerproduct_id,
  text: `<div class='clearfix'>${suggestion.product_name}<br/>${suggestion.producer_name}<br/>${suggestion.unit_display}</div>`,
  display: suggestion.product_name
});



export const StockRestocksNew = () => {

  const dispatch = useDispatch();
  const { currentBoutique: boutique } = useSelector(state => state.boutiques);
  const createAlert = d => dispatch(createAlertBase(d));
  const [selectedProducer, setSelectedProducer] = useState();
  const [producers, setProducers] = useState([]);

  const breadcrumbItems = breadcrumbs();
  const runSearch = runSearchBase({
    boutique,
    createAlert
  })
  const createRestock = createRestockBase({
    boutique,
    createAlert,
    selectedProducer
  })

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>

        {selectedProducer ? selectedProducer.existing_restock_id ? (
          <StockRestocksNewExistingRestock 
            existing_restock_id={selectedProducer.existing_restock_id} 
          />
        ) : (
          <RestocksDetailView
            addItemQuantityKey='producerproduct_id'
            allowEdit={true}
            allowRemoveItem={true}
            allowSave={true}
            allowSend={true}
            allowValidation={true}
            formatItems={BoutiqueRestock.formatItems}
            formatSuggestion={formatSuggestion}
            getTotals={BoutiqueRestock.getTotals}
            hideSearchPricing={true}
            itemReferenceKey='producerproduct_id'
            itemSubtitleKey='unit_display'
            itemTitleKey='product_name'
            maxItemQuantity={1000}
            onSave={createRestock}
            runSearch={runSearch}
            showConditionnement={true}
            suggestionReferenceKey='producerproduct_id'
          />
        ) : (
          <StockRestocksNewSelectProducer
             producers={producers}
             setSelectedProducer={setSelectedProducer}
          />
        )}
      </Container>
    </>
  )
}