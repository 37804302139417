import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { PlanningDay } from '@kelbongoo/shared-client-react-v2/dist/components/PlanningDay';
import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { getEvents } from './actions';
import { formatEvents } from './helpers';

const EVENTS_REFRESH_INTERVAL = 5 * 60 * 1000;


const HomeBase = () => {

	const [eventsLoading, setEventsLoading] = useState(true);
	const [events, setEvents] = useState([])
	const { loggedIn } = useSelector(state => state.currentUser);
  const { currentBoutique } = useSelector(state => state.boutiques);
	const loadData = getEvents({
		setEventsLoading,
		setEvents,
		createAlert,
		currentBoutique
	})

  useEffect(() => {
		
    if(!loggedIn || !currentBoutique){
      return;
    }

		loadData();

    const interval = setInterval(() => {
      loadData();
    }, EVENTS_REFRESH_INTERVAL);

    return () => clearInterval(interval);

  }, [loggedIn, currentBoutique]);

  return (
		<Container fluid>
			<Card className='mt-8 mb-8'>
				<Card.Header>Evenements du jour</Card.Header>
				<PlanningDay
					isHome={true}
					planning={false}
					events={formatEvents(events)}
					ready={!eventsLoading}
				/>
			</Card>
		</Container>
	)
}

export const Home = props => (
	<ErrorBoundary>
		<HomeBase {...props} />
	</ErrorBoundary>
)