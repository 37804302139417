import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash.clonedeep";

export const addComment = ({
  equipment,
  setEquipment,
  createAlert
}) => comment => {

  const caller = wrapClientApiCall({
    url: 'equipments/add-comment'
  });

  return caller({
    params: {
      equipment_id: equipment._id, 
      comment
    }
  })
    .then(({ comments }) => {
      
      const updatedEquipment = cloneDeep(equipment);
      updatedEquipment.comments = comments;
      setEquipment(updatedEquipment);
    })
    .catch(err => createAlert({ message: err.message }));
};