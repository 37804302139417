import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import * as Sentry from "@sentry/react";
import storage from 'redux-persist/lib/storage'

import { currentUserReducer, alertsReducer, actions, currentUserTransform } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { reducer as sessionReducer } from './reducers/session';
import { reducer as boutiquesReducer } from './reducers/boutiques';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['currentUser', 'boutiques'],
  transforms: [currentUserTransform]
};

const appReducer = combineReducers({
  currentUser: currentUserReducer,
  alerts: alertsReducer,
  session: sessionReducer,
  boutiques: boutiquesReducer
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'currentUser/logoutRequest') {
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
  enhancers: [sentryReduxEnhancer]
});

const persistor = persistStore(store);

// try to reinflate loggedIn user on app reload...
store.dispatch(actions.verifyAuth());

export {
  persistor,
  store
}
