import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';

import getDayStartEnd from '@kelbongoo/shared-methods/utils/getDayStartEnd';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { OrdersDetail } from '@kelbongoo/shared-client-react-v2/dist/components/OrdersDetail';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';

import {
  breadcrumbs
} from './helpers';
import {
  addComment as addCommentBase,
  addPayment as addPaymentBase,
  removePayment as removePaymentBase,
  runAddItemSearch as runAddItemSearchBase,
  updateItem as updateItemBase
} from './actions';
import { Order } from '../../../models/Order';
import { genericDetailLoader } from '../../../utils/genericDetailLoader';

export const NROrdersDetail = () => {

  const dispatch = useDispatch();

  const { order_id } = useParams();
  
  const { currentBoutique } = useSelector(state => state.boutiques);

  const [order, setOrder] = useState();
  const [boutiqueday, setBoutiqueDay] = useState();
  const createAlert = d => dispatch(createAlertBase(d));

  const { start, end } = getDayStartEnd(new Date());

  const addComment = addCommentBase({
    order,
    setOrder,
    createAlert
  })

  const addPayment = addPaymentBase({
    currentBoutique,
    boutiqueday,
    order,
    createAlert
  })

  const runAddItemSearch = runAddItemSearchBase({
    currentBoutique,
    createAlert
  })

  const updateItem = updateItemBase({
    order,
    setOrder,
    createAlert
  })

  const loadBoutiqueDay = () => 

    genericDetailLoader({
      setItem: setBoutiqueDay,
      params: {
        openedAt: { $gt: start, $lt: end },
        closedAt: { $exists: false },
        boutique: currentBoutique
      },
      url: 'boutiquedays/fetch',
      key: 'items',
      createAlert
    })

  const loadOrder = () => genericDetailLoader({
    setItem: setOrder,
    params: {
      order_id,
      getBoutiqueOrder: true,
      getPayments: true,
      getProducerProducts: true
    },
    url: 'orders/fetch-detail',
    key: 'order',
    createAlert
  })

  useEffect(() => {
    loadBoutiqueDay()
    loadOrder()
  }, [order_id, currentBoutique])

  if (!boutiqueday || !order) {
    return <LoadingSpinner />
  }

  const breadcrumbBarItems = breadcrumbs(order);

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbBarItems} />
      <Container fluid>
        <OrdersDetail
          currentOrder={order}
          addComment={addComment}
          getAmountPaid={Order.getAmountPaid}
          updateItem={updateItem}
          addPayment={addPayment}
          allowEdit={!!boutiqueday}
          allowPayment={!!boutiqueday}
          allowCredits={false}
          allowAddProducts={!order.isPaid()}
          runAddItemSearch={runAddItemSearch}
          showOrderBacs={true}
          noConsumerLink={true}
        />
      </Container>
    </>
  )
}