import React from 'react';
import { Link } from 'react-router-dom';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { MoneyDisplay } from '@kelbongoo/shared-client-react-v2/dist/components/MoneyDisplay';


export const BoutiqueDaysDetailOrdersRow = ({ item }) => {

  const itemsLength = item.items.length + item.reduced.length;
  
  // WARN consumers is empty
  // const consumer = (item.consumers || []).find(
  //   c => c._id === item.consumer_id
  // )

  return (
    <tr className={!item.paidAt ? 'warning' : ''}>
      <td>
        <Link to={`/boutiquedays/${item.boutiqueday_id}/orders/${item._id}`}>
          {formatDate(item.createdAt, 'HH:mm')}
        </Link>
      </td>
      {/* <td>
        {consumer ? `${consumer.firstname} ${consumer.lastname.toUpperCase()}` : <em className='text-muted'>Inconnu</em>}
      </td> */}
      <td className='text-center' style={{ width: '100px' }}>
        {itemsLength}
      </td>
      <td className='text-center' style={{ width: '150px' }}>
        {!item.paidAt ? <Icon name='warning-circle' className='text-warning' textRight /> : ''}
        <MoneyDisplay value={item.getAmountTotal()} />
        {item.paidAt ? <Icon name='check' className='text-success' textLeft /> : ''}
      </td>
    </tr>
  )
}