
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container'

import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import {
  Datatable,
  updateQuery as updateQueryBase,
  refreshData as refreshDataBase
} from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';

import { genericDetailLoader } from '../../../utils';

import {
  breadcrumbs,
  buildFilters,
  buildColumns
} from './helpers';
import {
  markPickedUp as markPickedUpBase
} from './actions';
import { NROrdersHomeRow } from './NROrdersHomeRow';
import { loadData } from '../Tasks/actions';

const selectFields = {
  consumer_name: 1,
  distribution_date: 1,
  items: 1,
  reduced: 1,
  nr_bacs: 1,
  paidAt: 1
}


export const NROrdersHome = () => {

  const dispatch = useDispatch();
  const breadcrumbItems = breadcrumbs();

  const { currentBoutique } = useSelector(state => state.boutiques);

  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [boutiqueday, setBoutiqueDay] = useState();
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState();
  const [page, setPage] = useState(1);

  const createAlert = data => dispatch(createAlertBase(data))

  const updateQuery = updateQueryBase({ setCurrentQuery, setPage, currentQuery })

  const nrOrdersStart = new Date();

  nrOrdersStart.setDate(nrOrdersStart.getDate() - 10);

  const refreshData = refreshDataBase({
    url: 'boutiquedays/fetch',
    setItems,
    setCount,
    createAlert,
    setLoading,
    selectorAddons: {
      locale: currentBoutique,
      status: 4,
      distribution_date: { $gt: nrOrdersStart }
    },
    selectFields,
    sortFields: { consumer_name: 1 }
  })

  const markPickedUp = markPickedUpBase({
    createAlert,
    refreshData
  });

  const loadBoutiqueDay = () => {
    genericDetailLoader({
      setLoading: setInitLoading,
      setItem: setBoutiqueDay,
      params: {
        boutique: currentBoutique,
      },
      url: 'boutiquedays/fetch-detail',
      key: 'boutiqueday',
      createAlert
    })
  }

  useEffect(loadBoutiqueDay, [currentBoutique])

  const columns = buildColumns();

  const loadData = () => {
    if (!currentQuery) return;

    refreshData({
      currentQuery,
      page,
      docsPerPage
    })
  }

  useEffect(loadData, [currentQuery, page, docsPerPage]);

  const { selectFilters, quickFilter, daterangeFilter } = buildFilters();

  if (initLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Datatable
          DatatableRow={NROrdersHomeRow}
          items={items.map(i => ({ ...i, markPickedUp: markPickedUp(i._id) }))}
          count={count}
          loading={loading}
          updateQuery={updateQuery}
          setDocsPerPage={setDocsPerPage}
          columns={columns}
          // selectFilters={selectFilters}
          // quickFilter={quickFilter}
          // daterangeFilter={daterangeFilter}
          useSelector={useSelector}
          dispatch={dispatch}
          showPagination={true}
          currentPage={page}
          updatePage={setPage}
        />
      </Container>
    </>
  )
}