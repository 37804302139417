import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';

export const breadcrumbs = (boutiqueday) => [
  {
    type: 'breadcrumb',
    title: 'Ventes',
    href: '/boutiquedays'
  }, {
    type: 'breadcrumb',
    title: formatDate(boutiqueday.openedAt, 'ddd DD MMM YYYY'),
    href: `/boutiquedays/${boutiqueday._id}`
  }, {
    type: 'breadcrumb',
    title: 'Ventes',
    href: `/boutiquedays/${boutiqueday._id}/orders`
  }, {
    type: 'breadcrumb',
    title: 'Nouvelle vente'
  }
];