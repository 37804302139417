import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const refreshData = ({
  boutique,
  inventory,
  setInventory,
  createAlert
}) => {

  const caller = wrapClientApiCall({
    url: 'inventories/fetch-detail'
  })

  return caller({
    params: { 
      boutique,
      inventory_id: inventory._id
    }
  })
    .then(({ inventory}) => {

      setInventory(inventory);
    })
    .catch(err => createAlert({ message: err.message }));
};