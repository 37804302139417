export const breadcrumbs = () => [{
  title: 'Stock',
  href: '/stock',
  type: 'breadcrumb'
},{
  title: 'Reappros stock',
  type: 'breadcrumb'
},{
  title: 'Creer un reappro',
  icon: 'plus',
  href: '/stock-restocks/new',
  type: 'menu'
}];