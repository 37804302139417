
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { RestocksDetailView } from '@kelbongoo/shared-client-react-v2/dist/components/RestocksDetailView';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { genericDetailLoader } from '../../../utils';
import { BoutiqueRestock } from '../../../models/BoutiqueRestock';
import { breadcrumbs } from './helpers';
import {
  addComment as addCommentBase,
  markArrived as markArrivedBase,
  onReceive as onReceiveBase,
  removeRestock as removeRestockBase,
  runSearch as runSearchBase,
  toggleItemCompleted as toggleItemCompletedBase,
  updateItemQuantity as updateItemQuantityBase,
  updateRestockItems as updateRestockItemsBase,
  validateRestock as validateRestockBase
} from './actions';


export const StockRestocksDetail = () => {

  const dispatch = useDispatch();
  const { restock_id } = useParams();
  const { currentBoutique: boutique } = useSelector(state => state.boutiques);
  const [loading, setLoading] = useState(true);
  const [restock, setRestock] = useState();
  const breadcrumbItems = breadcrumbs(restock);
  const createAlert = d => dispatch(createAlertBase(d))
  const baseParams = { boutique, boutiquerestock: restock, setRestock, createAlert }

  const addComment = addCommentBase(baseParams);
  const markArrived = markArrivedBase(baseParams);
  const onReceive = onReceiveBase(baseParams);
  const removeRestock = removeRestockBase(baseParams);
  const runSearch = runSearchBase(baseParams);
  const toggleItemCompleted = toggleItemCompletedBase(baseParams);
  const updateItemQuantity = updateItemQuantityBase(baseParams);
  const updateRestockItems = updateRestockItemsBase(baseParams);
  const validateRestock = validateRestockBase(baseParams);


  
  useEffect(() => {
    genericDetailLoader({
      setLoading, 
      setItem: setRestock,
      params: {
        boutiquerestock_id: restock_id, 
        boutique,
      },
      url: 'boutiquerestocks/fetch-detail',
      key: 'boutiquerestock',
      createAlert
    })
  }, [])

  if(loading){
    return <LoadingSpinner />
  }

  const boutiquerestock = new BoutiqueRestock(restock)

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid className='mb-5'>
        <RestocksDetailView
          restock={boutiquerestock}
          formatItems={BoutiqueRestock.formatItems}
          getTotals={BoutiqueRestock.getTotals}
          itemReferences={boutiquerestock.producerproducts}
          allowAddComment={true}
          addComment={addComment}
          onMarkArrived={markArrived}
          onReception={onReceive}
          onRemove={removeRestock}
          runSearch={runSearch}
          onSave={updateRestockItems}
          onValidation={validateRestock}
          toggleCompleted={toggleItemCompleted}
          updateQuantity={updateItemQuantity}
          showUpperView={true}
        />
      </Container>
    </>
  )
}