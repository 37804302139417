import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

const fields = { producer_name: 1, product_name: 1, producerproduct_id: 1, unit_display: 1, consumer_price: 1, tva: 1, producerproducts: 1, unit_weight: 1 }

export const runSearch = ({
  createAlert,
  currentBoutique
}) => ({ search, extra }) => {

  const caller = wrapClientApiCall({
    url: 'boutiqueproducerproducts/fetch'
  });

  return caller({
    params: {
      boutique: currentBoutique,
      // producerproduct_id: { $nin: usedRefs },
      product_name: search,
      producer_id: extra
    },
    options: {
      fields,
      sort: { producer_name: 1, product_name: 1 },
      limit: 8
    }
  })
    .then(({ items }) => { return items.map(item => ({ ...item, _id: item.producerproduct_id })) })
    .catch(err => createAlert({ message: err.message }));
};