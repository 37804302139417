import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { CaissesDetailView } from '@kelbongoo/shared-client-react-v2/dist/components/CaissesDetailView';
import { genericDetailLoader } from '../../../utils';
import { CaisseDay } from '../../../models/CaisseDay';
import { CaisseTransfer } from '../../../models/CaisseTransfer';
import { breadcrumbs } from './helpers';
import { 
  closeCaisse as closeCaisseBase,
  createTransfer as createTransferBase,
  fetchData as fetchDataBase,
  openCaisse as openCaisseBase,
  receiveTransfer as receiveTransferBase,
  removeTransfer as removeTransferBase, 
  updateTransferItems as updateTransferItemsBase,
  saveCloseData as saveCloseDataBase,
  saveOpenData as saveOpenDataBase
} from './actions';


export const BoutiqueDaysDetailCaisse = () => {

  const dispatch = useDispatch();
  const { boutiqueday_id } = useParams();
  const { currentBoutique: boutique } = useSelector(state => state.boutiques);
  const [boutiqueday, setBoutiqueDay] = useState();
  const [transfers, setTransfers] = useState();
  const [caisseday, setCaisseDay] = useState();
  const createAlert = d => dispatch(createAlertBase(d));
  const params = {
    boutiqueday_id,
    setCaisseDay,
    createAlert,
    setTransfers
  }

  const closeCaisse = closeCaisseBase(params);
  const createTransfer = createTransferBase(params);
  const fetchData = fetchDataBase(params);
  const openCaisse = openCaisseBase(params);
  const receiveTransfer = receiveTransferBase(params);
  const removeTransfer = removeTransferBase(params);
  const saveCloseData = saveCloseDataBase(params);
  const saveOpenData = saveOpenDataBase(params);
  const updateTransferItems = updateTransferItemsBase(params);
  
  useEffect(() => {
    genericDetailLoader({
      setItem: setBoutiqueDay,
      params: {
        boutiqueday_id, 
        boutique,
      },
      url: 'boutiquedays/fetch-detail',
      key: 'boutiqueday',
      createAlert
    })

    fetchData();
  }, [])

  if(!boutiqueday || !caisseday){
    return <LoadingSpinner />
  }

  const breadcrumbItems = breadcrumbs(boutiqueday);
  const activityClosed = !boutiqueday.openedAt;

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        
        <CaissesDetailView
          activityClosed={activityClosed}
          allowCheckoutReception={true}
          caisseday={new CaisseDay(caisseday)}
          closeCaisse={closeCaisse}
          createTransfer={createTransfer}
          openCaisse={openCaisse}
          receiveTransfer={receiveTransfer}
          removeTransfer={removeTransfer}
          saveCloseData={saveCloseData}
          saveOpenData={saveOpenData}
          transfers={transfers.map(t => new CaisseTransfer(t))}
          updateTransferItems={updateTransferItems}
          users={caisseday.users}
        />
      </Container>
    </>
  )
}