import * as Sentry from '@sentry/react'
import { ErrorBoundary as ErrorBoundaryBase } from '@kelbongoo/shared-client-react-v2/dist/components/ErrorBoundary';


const errorHandler = (error, errorInfo) => {

  Sentry.withScope((scope) => {
    scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}

export const ErrorBoundary = ErrorBoundaryBase({
  errorHandler
});
