import React, { useEffect, useState } from 'react';
import { Redirect, Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { CaissesDetailViewHeader } from '@kelbongoo/shared-client-react-v2/dist/components/CaissesDetailView/CaissesDetailViewHeader';
import { CaissesDetailViewOpen } from '@kelbongoo/shared-client-react-v2/dist/components/CaissesDetailView/CaissesDetailViewOpen';
import { CaissesDetailViewCheckins } from '@kelbongoo/shared-client-react-v2/dist/components/CaissesDetailView/CaissesDetailViewCheckins';
import { fetchData } from './actions';
import { buildParams } from './helpers';


export const BoutiqueDaysNew = () => {

  const dispatch = useDispatch();

  const [previousCaisse, setPreviousCaisse] = useState();
  const [startData, setStartData] = useState();
  const [transfers, setTransfers] = useState([]);
  const [transfersData, setTransfersData] = useState();
  const [loading, setLoading] = useState(true);
  const [existingBoutiqueDay, setExistingBoutiqueDay] = useState();
  const [redirectToCaisse, setRedirectToCaisse] = useState(false);
  const { currentBoutique } = useSelector(state => state.boutiques);
  const createAlert = d => dispatch(createAlert(d));

  const { boutiqueday_id } = useParams();

  useEffect(() => {
    fetchData({
      setTransfers,
      setLoading,
      setExistingBoutiqueDay,
      currentBoutique,
      setPreviousCaisse,
      createAlert
    })
  }, [])

  if(loading){
    return <LoadingSpinner />
  }

  if(redirectToCaisse){
    return <Redirect to={`/boutiquedays/${boutiqueday_id}/caisse`} />
  }

  const breadcrumbBarItems = [
    { type: 'breadcrumb', title: 'Ventes', href: '/boutiquedays' },
    { type: 'breadcrumb', title: 'Nouvelle vente' }
  ]
  const showCheckins = !!startData;

  const { openParams, checkinsParams } = buildParams({
    previousCaisse,
    startData,
    transfers,
    transfersData,
    setTransfers,
    setTransfersData,
    setStartData,
    setRedirectToCaisse,
    createAlert
  })

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbBarItems} Link={Link} />
      <Container fluid>
        {existingBoutiqueDay ? (
          <div className='text-center'>
            <div className='mb-3'>
              <Image src='http://cdn.kbgstaging.org/images/banane-sad.svg' style={{width: '150px'}} />
            </div>
            <div className='italic'>
              Il y a d&eacute;j&agrave; une vente en cours !
            </div>
          </div>
        ) : (
          <Row>
            <Col sm={12} md={{ span: 6, offset: 3 }}>
              <div className='mb-2'>
                <CaissesDetailViewHeader
                  stepNumber={1}
                  stepName='Ouverture de caisse'
                />
                <CaissesDetailViewOpen 
                  {...openParams} 
                />
              </div>

              {showCheckins && (
                <div className='mb-2'>
                  <CaissesDetailViewHeader
                    stepNumber={2}
                    stepName='Entrées de caisse'
                  />
                  <CaissesDetailViewCheckins 
                    {...checkinsParams} 
                  />
                </div>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </>
  )
}