import model from '@kelbongoo/shared-methods/caissedays/model';
import clientModel from '@kelbongoo/shared-client-methods/caissedays/model';
import collection from '@kelbongoo/shared-methods/caissedays/collection';

class CaisseDay {

  constructor(data){

    Object.assign(this, data);
  }

  hasOpeningWarning(){
    return false;
  }

  hasCheckinsWarning(){
    return false;
  }
}

Object.assign(
  CaisseDay.prototype,
  model,
  clientModel
);

Object.assign(
  CaisseDay,
  collection
);

export {
  CaisseDay
}