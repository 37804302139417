import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from 'lodash.clonedeep';

export const markArrived = ({
  boutique,
  boutiquerestock,
  createAlert,
  setRestock
}) => () => {

  const caller = wrapClientApiCall({
    url: 'boutiques/mark-boutiquerestock-arrived'
  })

  return caller({
    params: {
      boutique,
      boutiquerestock_id: boutiquerestock._id,
    }
  })
    .then(({ arrivedAt, arrivedBy }) => {
      createAlert({ type: 'success' });

      const updatedDoc = cloneDeep(boutiquerestock);
      updatedDoc.arrivedAt = arrivedAt;
      updatedDoc.arrivedBy = arrivedBy;
      setRestock(updatedDoc);
    })
    .catch(err => createAlert({ message: err.message }));
};