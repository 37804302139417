export const breadcrumbs = boutiqueproducerproduct => boutiqueproducerproduct && [{
  type: 'breadcrumb',
  title: 'Stock',
  href: '/stock'
},{
  type: 'breadcrumb',
  title: boutiqueproducerproduct.product_name,
  href: `/stock/${boutiqueproducerproduct.producerproduct_id}`
},{
  type: 'breadcrumb',
  title: 'Evenements'
}]