import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { 
  Datatable,
  updateQuery as updateQueryBase,
  refreshData as refreshDataBase
} from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { breadcrumbs, getColumns, getFilters } from './helpers';
import { StockHomeRow } from './StockHomeRow';


export const StockHome = () => {

  const dispatch = useDispatch();
  const { currentBoutique } = useSelector(state => state.boutiques);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState(); // important !!!
  const [page, setPage] = useState(1);
  const createAlert = data => dispatch(createAlertBase(data))
  const updateQuery = updateQueryBase({ setCurrentQuery, setPage, currentQuery })
  const refreshData = refreshDataBase({
    url: 'boutiqueproducerproducts/fetch',
    setItems,
    setCount,
    createAlert,
    setLoading,
    selectorAddons: {
      boutique: currentBoutique,
      preordered: null
    }, // optional
    selectFields: {
      producer_name: 1,
      product_name: 1,
      producerproduct_id: 1,
      last_inventory: 1,
      producer_id: 1,
      quantity_available: 1,
      unit_display: 1,
      sales_average: 1
    }, // optional
    sortFields: { producer_name: 1, product_name: 1 } // optional
  })
  useEffect(() => {

    if(!currentQuery){ // important !!!
      return;
    }

    refreshData({
      currentQuery, 
      page,
      docsPerPage
    })
  }, [currentQuery, page, docsPerPage]);

  const breadcrumbItems = breadcrumbs();
  const columns = getColumns();
  const { searchFilter, selectFilters, quickFilter } = getFilters();
  console.log('items', items)

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Datatable
          DatatableRow={StockHomeRow} // required
          items={items} // required
          count={count} // required
          loading={loading} // required
          updateQuery={updateQuery} // required
          setDocsPerPage={setDocsPerPage} // required
          columns={columns} // required
          selectFilters={selectFilters} // optional
          searchFilter={searchFilter}
          quickFilter={quickFilter}
          dispatch={dispatch}
          useSelector={useSelector}
          updatePage={setPage}
          showPagination={true}
          currentPage={page}
        />
      </Container>
    </>
  )
}