import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addPayment = ({
  boutiqueorder,
  boutiqueday_id,
  currentBoutique,
  createAlert,
  loadBoutiqueOrder
}) => ({ payments, items, reduced, consumer_id }) => {

  const caller = wrapClientApiCall({
    url: 'boutiques/add-locale-payment'
  });

  return caller({
    params: {
      boutiqueorder_id: boutiqueorder._id,
      boutiqueday_id,
      boutique: currentBoutique,
      items,
      reduced,
      payment: payments.slice(-1)[0],
      consumer_id,
      userId: '2W8X2SaMhytesy4ne', // WARN

    },
  })
    .then(() => {
      createAlert({ type: 'success' });
      loadBoutiqueOrder();
    })
    .catch((err) => createAlert({ message: err.message }));
};