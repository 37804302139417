import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';

export const EquipmentsNewListItem = ({ item }) => {

  const [running, setRunning] = useState(false);
  const handleClick = () => {
    setRunning(true);
    return item.addEquipment()
      .finally(() => setRunning(false))
  }


  return (
    <tr>
      <td style='vertical-align: middle'>
        <div>
          {item.type_name}
        </div>
        <div class='text-muted font-sm'>
          {item.code}
        </div>
      </td>
      <td style='vertical-align: middle; text-align:center;'>
        <Button variant='outline-secondary' size='sm' disabled={running ? 'disabled' : ''} onClick={handleClick}>
          {running ? (
            <span class="spinner-border spinner-border-sm" />
          ) : (
            <Icon name='plus' />
          )}
        </Button>
      </td>
    </tr>
  )
}