export const buildFilters = () => {

  const daterangeFilter = {
    keys: ['createdAt']
  }
  const quickFilter = {
    value: 'all',
    defaultValue: 'all',
    keys: ['paidAt'],
    items: [
      { value: 'all', name: 'Toutes' },
      { value: 'paid', name: 'Payées' },
      { value: 'not-paid', name: 'À régler' },
    ],
    valueMapping(value){
      return {
        'all': { paidAt: undefined},
        'paid': { paidAt: { $exists: true }},
        'not-paid': { paidAt: { $exists: false }},
      }[value]
    }
  }

  return {
    daterangeFilter,
    quickFilter
  }
}