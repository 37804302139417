import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from 'lodash.clonedeep';

export const addComment = ({
  boutiqueday,
  setBoutiqueDay,
  createAlert
}) => comment => {

  const caller = wrapClientApiCall({
    url: 'boutiquedays/add-comment'
  });

  return caller({
    params: {
      reference_id: boutiqueday._id, 
      comment,
      context: 'boutique',
      userId: '2W8X2SaMhytesy4ne', // WARN
    }
  })
    .then(({ comments }) => {
      const updatedBoutiqueDay = cloneDeep(boutiqueday);
      updatedBoutiqueDay.comments = comments;
      setBoutiqueDay(updatedBoutiqueDay);
    })
    .catch(err => createAlert({ message: err.message }));
};