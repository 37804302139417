import React from 'react';
import { Link } from 'react-router-dom';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';

export const StockHomeRow = ({ item }) => {
  const {
    product_name,
    producer_name,
    unit_display,
    producerproduct_id,
    sales_average,
    quantity_available,
    last_inventory
  } = item;

  return (
    <tr>
      <td>
        <Link to={`/stock/${producerproduct_id}`}>
          {product_name}
        </Link>
        <div className='italic text-muted'>
          {producer_name}
        </div>
        <div className='italic text-muted'>
          {unit_display}
        </div>
      </td>
      <td className='text-center ' style={{verticalAlign: 'middle'}}>
        {last_inventory ? (formatDate(last_inventory, 'YYYY-MM-DD HH:mm')) : '-'}
      </td>
      <td className='text-lg text-center' style={{verticalAlign: 'middle'}}>
        {sales_average}
      </td>
      <td className='text-lg bold text-center' style={{verticalAlign: 'middle'}}>
        {quantity_available}
      </td>
    </tr>
  )
}