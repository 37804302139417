import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';


export const getEvents = ({
  setEventsLoading,
  setEvents,
  createAlert,
  currentBoutique
}) => () => {

  setEventsLoading(true);

  const caller = wrapClientApiCall({
    url: 'boutiques/get-daterange-events'
  })

  return caller({
    params: { 
      boutique: currentBoutique, 
      start: new Date(), 
      end: new Date(),
      application: 'boutiques'
    }
  })
    .then((events) => setEvents(events))
    .catch(err => createAlert({ message: err.message }))
    .finally(() => setEventsLoading(false));
}