import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const removeRestock = ({
  boutique,
  boutiquerestock,
  setRestock,
  createAlert
}) => () => {

  const caller = wrapClientApiCall({
    url: 'boutiquerestocks/remove-restock'
  })

  return caller({
    params: {
      boutique,
      boutiquerestock_id: boutiquerestock._id,
    }
  })
    .then(() => setRestock())
    .catch(err => createAlert({ message: err.message }));
};