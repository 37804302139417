
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { TrackedItemEventsList } from '@kelbongoo/shared-client-react-v2/dist/components/TrackedItemEventsList';
import { breadcrumbs } from './helpers';
import { genericDetailLoader } from '../../../utils';
import { createStockAdjustment as createStockAdjustmentBase } from './actions';

const selectFields = {
  date: 1,
  quantity: 1,
  quantity_available: 1,
  delta: 1,
  comment: 1,
  event_type: 1
};
const sortFields = {
  date: -1
};


export const StockDetailEvents = () => {

  const dispatch = useDispatch();
  const { producerproduct_id } = useParams();
  const { currentBoutique: boutique } = useSelector(state => state.boutiques);
  const [loading, setLoading] = useState(true);
  const [epp, setEpp] = useState();
  const createAlert = data => dispatch(createAlertBase(data))
  const breadcrumbItems = breadcrumbs(epp);

  const createStockAdjustment = createStockAdjustmentBase({
    boutique,
    epp,
    createAlert
  })
  
  useEffect(() => {
    genericDetailLoader({
      setLoading, 
      setItem: setEpp,
      params: {
        producerproduct_id, 
        boutique,
      },
      url: 'boutiqueproducerproducts/fetch-detail',
      key: 'boutiqueproducerproduct',
      createAlert
    })
  }, [])

  if(loading){
    return <LoadingSpinner />
  }

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <TrackedItemEventsList
          fetchUrl='boutiqueproducerproducts/fetch-item-events'
          selectorAddons={{
            producerproduct_id: epp.producerproduct_id, 
				    boutique 
          }}
          selectFields={selectFields}
          sortFields={sortFields}
          allowEdit={true}
          item={epp}
          title={epp.product_name}
          subtitle={epp.producer_name}
          subsubtitle={epp.unit_display}
          createStockAdjustment={createStockAdjustment}
        />
      </Container>
    </>
  )
}