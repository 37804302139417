import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import cloneDeep from "lodash/cloneDeep";

export const updateTransferItems = ({
  transfers,
  createAlert,
  setTransfers
}) => (transfer_id, items) => {

  const caller = wrapClientApiCall({
    url: 'caissetransfers/update-items'
  })

  return caller({ 
    params: { 
      transfer_id, 
      items 
    }
  })
    .then(updatedTransfer => {

      const updatedTransfers = cloneDeep(transfers)
      let ind = updatedTransfers.findIndex(
        t => t._id === transfer_id
      );
      updatedTransfers[ind] = updatedTransfer;
      return setTransfers(updatedTransfers)
    })
    .catch(err => createAlert({ message: err.message }));
};