import { run as formatMoney } from '@kelbongoo/shared-methods/utils/formatMoney';

export const getDisplays = (boutiqueday) => {

  return {
    caDisplay: formatMoney(Math.floor(boutiqueday.ca)),
    ordersDisplay: parseInt(boutiqueday.orderscount),
    articlesDisplay: parseInt(boutiqueday.articles) || 0,
    panierMoyenDisplay: formatMoney(parseInt(boutiqueday.ca) / parseInt(boutiqueday.orderscount))
  }
}