import React from 'react';
import { Link } from 'react-router-dom';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';


export const StockRestocksHomeRow = ({ item }) => {

  const showSent = false;

  return (
    <tr className={item.isPreparing() ? 'info' : ''}>
      <td>
        <Link to={`/stock/restocks/${item._id}`}>
          {item.delivery_date ? (
            <span>{formatDate(item.delivery_date, 'ddd DD MMM YYYY')}</span>
          ) : (
            <i>Pas encore programm&eacute;e</i>
          )}
        </Link>
      </td>
      <td className=''>
        {item.producer_name}
      </td>
      <td className='text-center'>
        {item.items.length}
      </td>
      <td className='bold text-center'>
        {showSent ? (
          <Icon name='paper-plane' textRight />
        ) : null}
        {item.isReceiving() ? (
          <span>réception</span>
        ) : (
          <span>{item.getStatusTitle()}</span>
        )}
      </td>
    </tr>
  )
}