
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import { BreadcrumbBar } from '@kelbongoo/shared-client-react-v2/dist/components/BreadcrumbBar';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { 
  Datatable,
  updateQuery as updateQueryBase,
  refreshData as refreshDataBase
} from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { 
  breadcrumbs,
  buildFilters
} from './helpers';
import {
  addEquipment as addEquipmentBase
} from './actions';
import { EquipmentsNewListItem } from './EquipmentsNewListItem';


export const EquipmentsNew = () => {

  const dispatch = useDispatch();
  const { currentBoutique } = useSelector(state => state.boutiques);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState();
  const [page, setPage] = useState(1);
  const createAlert = d => dispatch(createAlertBase(d))
  const updateQuery = updateQueryBase({ setCurrentQuery, setPage, currentQuery });

  const addEquipment = addEquipmentBase({
    currentBoutique,
    createAlert,
    refreshData
  })

  const refreshData = refreshDataBase({
    url: 'equipments/get-addable-equipments',
    setItems,
    setCount,
    createAlert,
    setLoading,
    selectorAddons: {
      context_value: currentBoutique,
      context_key: 'boutique'
    }, 
    selectFields: {
      type: 1,
      code: 1,
      product_name: 1,
      unit_display: 1
    }, 
    sortFields: { type: 1 }
  })

  useEffect(() => {

    if(!currentQuery){
      return;
    }

    refreshData({
      currentQuery, 
      page,
      docsPerPage
    })
  }, [currentQuery, page, docsPerPage]);
  
  const { searchFilter } = buildFilters();
  const breadcrumbItems = breadcrumbs();

  return (
    <>
      <BreadcrumbBar breadcrumbs={breadcrumbItems} Link={Link} />
      <Container fluid>
        <Datatable
          columns={[
            { title: 'Objet' },
            { title: '', width: '80px' }
          ]}
          DatatableRow={EquipmentsNewListItem} 
          items={items.map(i => ({ ...i, addEquipment: addEquipment(i._id) }))} 
          count={count} 
          loading={loading} 
          updateQuery={updateQuery} 
          setDocsPerPage={setDocsPerPage} 
          searchFilter={searchFilter} 
          useSelector={useSelector}
          dispatch={dispatch}
          showPagination={true}
          currentPage={page}
          updatePage={setPage}
        />
      </Container>
    </>
  )
}