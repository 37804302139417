import { formatBoutiqueRestock } from './formatBoutiqueRestock';
import { formatInventory } from './formatInventory';
import { formatTasks } from './formatTasks';

export const formatEvents = events => {

  return Object.keys(events).reduce((acc,key) => {

		switch(key){
			case 'taskinstances': acc = acc.concat(formatTasks(events[key], { url: '/tasks' })); break;
			case 'boutiquerestocks': acc = acc.concat((events[key]).map(i => formatBoutiqueRestock(i))); break;		
			case 'inventories': acc = acc.concat((events[key]).map(i => formatInventory(i))); break;
			default: break;
		}
		return acc;
	}, [])
};